import React, { Component } from "react";
import * as Yup from "yup";
import { connect } from "react-redux";
import { Formik, Form } from "formik";
import { Row, Col } from "antd";
import { withRouter } from "react-router-dom";

import { MasterConstant, sdata, period } from "./constant";
import { Label, Input, Button, Select, DatePicker } from "components/Form";
import { getYear, savePeriod, getPeriod } from "redux/organization/action";
import { getSettings } from "redux/organization/action";
import { getOrgId } from "modules/helper";
import { ButtonConst } from "App/AppConstant.js";
var orgID = getOrgId();
const periodValidation = Yup.object().shape({
  periodname: Yup.string().trim().required(" "),
  year: Yup.string().trim().required(" "),
  periodSlecte: Yup.string().trim().required(" "),
});

class Period extends Component {
  constructor(props) {
    super(props);
    this.state = {
      btnDisable: false,
      reviewPeriod: [],
      yearSelData: [],
      initVal: {
        periodid: 0,
        periodname: "",
        year: "",
        yearID: 0,
        periodSlecte: "",
        periodSlecteID: 0,
      },
    };
  }
  async componentDidMount() {
    try {
      orgID = getOrgId();
      const { preEdit, preView, editData } = this.props;
      (preEdit || preView) && this.setDefault(editData);
      await this.props.getYear(orgID);
      await this.props.getSettings(orgID);
    } catch (error) {
      console.log(error);
    }
  }
  async componentDidUpdate(prevProps) {
    try {
      const { yearDetails } = this.props;
      if (yearDetails !== prevProps.yearDetails) {
        let select = [];
        yearDetails?.forEach((a, i) => {
          select.push({ id: a.id, value: a.yearname });
        });
        this.setState({ yearSelData: select });
      }
    } catch (error) {
      console.log(error);
    }
  }
  setDefault = (data) => {
    try {
      let periodSlecte = period.find((x) => x.id === data.periodselection);
      let reviewData = [];
      let editData = {
        periodid: data.periodid,
        periodname: data.periodname,
        year: data.yearname,
        yearID: data.yearselection,
        periodSlecte: periodSlecte.value,
        periodSlecteID: periodSlecte.id,
      };
      data?.reviewPeriods?.forEach((x, i) => {
        reviewData.push({
          reviewid: x.reviewid,
          reviewName: x.reviewperiodname,
          startDate: x.startdate,
          endDate: x.enddate,
        });
      });
      this.setState({ initVal: editData, reviewPeriod: reviewData });
    } catch (error) {
      console.log(error);
    }
  };
  selectUI = (val, name, setFieldValue, error) => {
    try {
      const { preView } = this.props;
      return (
        <Select
          data={name === "year" ? this.state.yearSelData : period}
          value={val}
          defaultValue={val}
          disabled={preView}
          withID
          className={error ? "empty" : ""}
          onChange={(value, data) => {
            setFieldValue(name, value);
            if (name === "periodSlecte") {
              setFieldValue("periodSlecteID", data.id);
              let review = [];
              review = sdata[data.id - 1];
              data.id === 1 ? this.setMonth(review) : this.setReview(review);
            } else if (name === "year") setFieldValue("yearID", data.id);
          }}
        />
      );
    } catch (error) {
      console.log(error);
    }
  };
  setMonth = (data) => {
    try {
      const { settingsDetails } = this.props;
      let date = parseInt(settingsDetails.frommonth.split("-")[1]) - 1;
      let j = 0;
      let c = [];
      for (let i = 0; i < 12; i++) {
        if (date < 12) {
          c.push({
            reviewid: 0,
            reviewName: data[date],
            startDate: "",
            endDate: "",
          });
          date = date + 1;
        } else {
          c.push({
            reviewid: 0,
            reviewName: data[j],
            startDate: "",
            endDate: "",
          });
          j = j + 1;
        }
      }
      this.setState({ reviewPeriod: c });
    } catch (error) {
      console.log(error);
    }
  };
  setReview = (data) => {
    try {
      let c = [];
      data?.forEach((b, i) => {
        c.push({ reviewid: 0, reviewName: b, startDate: "", endDate: "" });
      });
      this.setState({ reviewPeriod: c });
    } catch (error) {
      console.log(error);
    }
  };
  handleReviewName = (val, i) => {
    try {
      const { reviewPeriod } = this.state;
      reviewPeriod[i].reviewName = val.target.value;
      this.setState({ reviewPeriod });
    } catch (error) {
      console.log(error);
    }
  };
  handleDate = (val, name, i) => {
    try {
      const { reviewPeriod } = this.state;
      if (name === "startDate") reviewPeriod[i].startDate = val;
      else reviewPeriod[i].endDate = val;
      this.setState({ reviewPeriod });
    } catch (error) {
      console.log(error);
    }
  };
  handleSubmit = async (values, { setSubmitting }) => {
    try {
      const { reviewPeriod } = this.state;
      this.setState({ btnDisable: true });
      setTimeout(() => {
        this.setState({ btnDisable: false });
      }, 500);
      let postData = {
        periodid: values.periodid,
        periodname: values.periodname.trim(),
        yearselection: values.yearID,
        periodselection: values.periodSlecteID,
        orgid: orgID,
        reviewPeriod: [],
      };
      reviewPeriod?.forEach((a, i) => {
        postData?.reviewPeriod.push({
          reviewid: a.reviewid,
          reviewperiodname: a.reviewName,
          startdate: a.startDate !== "" ? a.startDate : null,
          enddate: a.endDate !== "" ? a.endDate : null,
        });
      });
      await this.props.savePeriod(postData);
      this.props.setPrCount(0);
      await this.props.getPeriod(orgID);
      setSubmitting(false);
    } catch (error) {
      console.log(error);
    }
  };
  render() {
    const { initVal, btnDisable, reviewPeriod } = this.state;
    const { preView } = this.props;
    return (
      <div className="tableDiv anime" key="tbl">
        <div className="headDiv">
          <h2>{"Add Period"}</h2>
        </div>
        <Formik
          initialValues={initVal}
          validationSchema={periodValidation}
          onSubmit={this.handleSubmit}
          enableReinitialize
        >
          {({
            values,
            errors,
            touched,
            onBlur,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <Label
                    title={MasterConstant.pname + MasterConstant.star}
                    className={
                      errors.periodname && touched.periodname ? "empty" : ""
                    }
                  />
                  <Input
                    disabled={preView}
                    name="periodname"
                    onBlur={handleBlur}
                    value={values.periodname}
                    handleChange={handleChange}
                    className={
                      errors.periodname && touched.periodname ? "empty" : ""
                    }
                  />
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <Label
                    title={MasterConstant.year + MasterConstant.star}
                    className={
                      errors.year && touched.year && values.year === ""
                        ? "empty"
                        : ""
                    }
                  />
                  {values.year === "" &&
                    this.selectUI(
                      "",
                      "year",
                      setFieldValue,
                      errors.year && touched.year && values.year === ""
                    )}
                  {values.year !== "" &&
                    this.selectUI(values.year, "year", setFieldValue)}
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <Label
                    title={MasterConstant.peselect + MasterConstant.star}
                    className={
                      errors.periodSlecte &&
                      touched.periodSlecte &&
                      values.periodSlecte === ""
                        ? "empty"
                        : ""
                    }
                  />
                  {values.periodSlecte === "" &&
                    this.selectUI(
                      "",
                      "periodSlecte",
                      setFieldValue,
                      errors.periodSlecte &&
                        touched.periodSlecte &&
                        values.periodSlecte === ""
                    )}
                  {values.periodSlecte !== "" &&
                    this.selectUI(
                      values.periodSlecte,
                      "periodSlecte",
                      setFieldValue
                    )}
                </Col>
              </Row>
              <div className="tableView">
                <table className="valueDiv">
                  {values.periodSlecteID !== 0 && (
                    <thead>
                      <tr>
                        <th>
                          {values.periodSlecteID === 1
                            ? MasterConstant.month
                            : values.periodSlecteID === 2
                            ? MasterConstant.quart
                            : values.periodSlecteID === 3
                            ? MasterConstant.half
                            : MasterConstant.years}
                        </th>
                        <th>{MasterConstant.stDate}</th>
                        <th>{MasterConstant.enDate}</th>
                      </tr>
                    </thead>
                  )}
                  <tbody>
                    {reviewPeriod.map((a, i) => (
                      <tr key={i}>
                        {!preView ? (
                          <td>
                            <Input
                              value={a.reviewName}
                              handleChange={(val) =>
                                this.handleReviewName(val, i)
                              }
                            />
                          </td>
                        ) : (
                          <td>{a.reviewName}</td>
                        )}
                        {!preView ? (
                          <td>
                            <DatePicker
                              value={a.startDate}
                              handleChange={(val) =>
                                this.handleDate(val, "startDate", i)
                              }
                            />
                          </td>
                        ) : (
                          <td>{a.startDate}</td>
                        )}
                        {!preView ? (
                          <td>
                            <DatePicker
                              value={a.endDate}
                              handleChange={(val) =>
                                this.handleDate(val, "endDate", i)
                              }
                            />
                          </td>
                        ) : (
                          <td>{a.endDate}</td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="btnDiv">
                <div className="btn anime">
                  <Button onClick={() => this.props.setPrCount(0)}>
                    {ButtonConst.cancel}
                  </Button>
                  {values.periodSlecteID !== 0 && !preView && (
                    <Button type="submit" disabled={btnDisable}>
                      {ButtonConst.submit}
                    </Button>
                  )}
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  yearDetails: state.organization.yearDetails,
  settingsDetails: state.organization.settingsDetails,
});
const mapDispatchToProps = (dispatch) => ({
  getYear: (id) => dispatch(getYear(id)),
  savePeriod: (id) => dispatch(savePeriod(id)),
  getPeriod: (id) => dispatch(getPeriod(id)),
  getSettings: (id) => dispatch(getSettings(id)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Period));
