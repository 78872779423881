import React, { Component } from "react";
import * as Yup from "yup";
import { Row, Col, message } from "antd";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Formik, Form } from "formik";
import {
  PlusCircleOutlined,
  MinusCircleOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

import { ButtonConst } from "App/AppConstant.js";
import { EmpConst } from "./../constant";
import { Label, Input, Button, Select } from "components/Form";
import { getOrgId } from "modules/helper";
import { getUserListByOrgId, getSettings } from "redux/organization/action";
import { saveAppraiser, getAppraiser } from "redux/employee/action";

var orgID = getOrgId();
const AppraisalValidation = Yup.object().shape({
  code: Yup.string().trim().required(" "),
  name: Yup.string().trim().required(" "),
});
class Appraiser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: [],
      selectData: [],
      empid: 0,
      totalWeightage: 0,
      hierarchy: 1,
      weightage: 0,
      weightageError: false,
      btnDisable: false,
      selfapp: false,
      initVal: { id: 0, code: "", codeId: 0, name: "" },
    };
  }
  async componentDidMount() {
    try {
      const { selfapp, match } = this.props;
      orgID = getOrgId();
      let empid = JSON.parse(localStorage.employeeId);
      this.setState({ empid: empid.empId });
      await this.props.getSettings(orgID);
      await this.props.getUserListByOrgId(orgID + "/" + empid.empId);
      match.params.id &&
        (await this.props.getAppraiser(parseInt(window.atob(match.params.id))));
      selfapp && this.setDefault();
    } catch (error) {
      console.log(error);
    }
  }
  componentDidUpdate(prevProps) {
    try {
      const { userList, appraiserDetail, match } = this.props;
      if (userList !== prevProps.userList && !match.params.id) {
        let data = [];
        userList?.forEach((e, i) => {
          data.push({ id: e.userid, value: e.empcode, name: e.fullname });
        });
        this.setState({ selectData: data });
      }
      if (appraiserDetail !== prevProps.appraiserDetail) {
        let data = [];
        let userData = [];
        let total = 0;
        appraiserDetail?.forEach((a, i) => {
          data.push({
            id: a.id,
            empid: a.empid,
            appraiserid: a.appraiserid,
            code: a.empcode,
            name: a.name,
            level: a.level,
            weightage: a.weightage,
          });
          total = total + a.weightage;
        });
        if (userList) {
          userList?.forEach((a, i) => {
            if (!data.find((x) => x.appraiserid === a.userid)) {
              userData.push({
                id: a.userid,
                value: a.empcode,
                name: a.fullname,
              });
            }
          });
        }
        this.setState({
          tableData: data,
          selectData: userData,
          totalWeightage: total,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }
  setDefault = () => {
    try {
      const { empid, selectData, tableData } = this.state;
      const { selfapp } = this.props;
      if (selfapp && tableData.length === 0) {
        let setData = selectData.find((x) => x.id === empid);
        let dflData = {
          code: setData.value,
          codeId: setData.id,
          name: setData.name,
        };
        this.setState({ initVal: dflData, selfapp: tableData.length <= 0 });
      }
    } catch (error) {
      console.log(error);
    }
  };
  selectUI = (val, name, setFieldValue, error) => {
    try {
      const { selectData, selfapp } = this.state;
      return (
        <Select
          data={selectData}
          value={val}
          defaultValue={val}
          disabled={selfapp}
          withID
          className={error ? "empty" : ""}
          onChange={(value, data) => {
            let setData = selectData.find((x) => x.id === data.id);
            setFieldValue(name, setData.value);
            setFieldValue("codeId", setData.id);
            setFieldValue("name", setData.name);
          }}
        />
      );
    } catch (error) {
      console.log(error);
    }
  };
  handleHierarchy = (val) => {
    try {
      const { hierarchy } = this.state;
      let setHrchy = val ? hierarchy + 1 : !val ? hierarchy - 1 : 1;
      this.setState({ hierarchy: setHrchy === 0 ? 1 : setHrchy });
    } catch (error) {
      console.log(error);
    }
  };
  handleWeight = (val) => {
    try {
      const { tableData } = this.state;
      let total = 0;
      tableData?.forEach((a) => {
        total = total + a.weightage;
      });
      total = total + val;
      this.setState({
        weightage: val,
        weightageError: val > 0 && total <= 100 ? false : true,
      });
    } catch (error) {
      console.log(error);
    }
  };
  handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const { hierarchy, weightage, tableData, selectData, empid } = this.state;
      const { settingsDetails } = this.props;
      this.setState({ btnDisable: true });
      setTimeout(() => {
        this.setState({ btnDisable: false });
      }, 4500);
      if (settingsDetails.settingsid) {
        let sortselect = [];
        let flag = false;
        let total = 0;
        let hir = tableData.length > 0 ? 1 : 0;
        tableData?.forEach((a) => {
          total = total + a.weightage;
          hir = a.level > hir ? a.level : hir;
        });
        total = total + weightage;
        if (weightage <= 0) {
          flag = true;
          this.setState({ weightageError: true });
        }
        if (total > 100) {
          flag = true;
          this.setState({ weightageError: true });
        }
        if (hierarchy > hir + 1) {
          flag = true;
          message.error("Please Enter Valid Hierarchy ID");
        }
        if (!flag) {
          let setDaata = {
            id: values.id,
            empid: empid,
            appraiserid: values.codeId,
            code: values.code,
            name: values.name,
            level: hierarchy,
            weightage: weightage,
          };
          tableData.unshift(setDaata);
          selectData?.forEach((a, i) => {
            let ckeck = tableData.find((x) => x.appraiserid === a.id);
            !ckeck && sortselect.push(a);
          });
          this.setState({
            tableData,
            hierarchy: 1,
            weightage: 0,
            totalWeightage: total,
            selectData: sortselect,
            selfapp: false,
          });
          this.setinitDfl();
          resetForm();
          setSubmitting(false);
        }
      } else message.error("please Fill Out Settings First");
    } catch (error) {
      console.log(error);
    }
  };
  subWithSetting = async (values, { setSubmitting, resetForm }) => {
    try {
      const { hierarchy, tableData, selectData, empid } = this.state;
      const { settingsDetails } = this.props;
      this.setState({ btnDisable: true });
      setTimeout(() => {
        this.setState({ btnDisable: false });
      }, 4500);
      if (settingsDetails.settingsid) {
        let sortselect = [];
        let flag = false;
        let hir = tableData.length > 0 ? 1 : 0;
        let wight = tableData.length > 0 ? 0 : 100;
        let total = 0;
        let divid = tableData.length > 0 ? 100 / (tableData.length + 1) : 100;
        tableData?.forEach((a, i) => {
          tableData[i].weightage = Math.floor(divid);
          total = total + Math.floor(divid);
          hir = a.level > hir ? a.level : hir;
        });
        wight = tableData.length > 0 ? 100 - total : wight;
        total = total + (100 - total);
        if (hierarchy > hir + 1) {
          flag = true;
          message.error("Please Enter Valid Hierarchy ID");
        }
        if (!flag) {
          let setDaata = {
            id: values.id,
            empid: empid,
            appraiserid: values.codeId,
            code: values.code,
            name: values.name,
            level: hierarchy,
            weightage: wight,
          };
          tableData.unshift(setDaata);
          selectData?.forEach((a, i) => {
            let ckeck = tableData.find((x) => x.appraiserid === a.id);
            !ckeck && sortselect.push(a);
          });
          this.setState({
            tableData,
            hierarchy: 1,
            weightage: 0,
            totalWeightage: total,
            selectData: sortselect,
            selfapp: false,
          });
          this.setinitDfl();
          resetForm();
          setSubmitting(false);
        }
      } else message.error("please Fill Out Settings First");
    } catch (error) {
      console.log(error);
    }
  };
  setinitDfl = () => {
    try {
      let dfl = { code: "", codeId: 0, name: "" };
      this.setState({ initVal: dfl });
    } catch (error) {
      console.log(error);
    }
  };
  submitData = async () => {
    try {
      const { tableData, totalWeightage ,empid } = this.state;
      const { isAdded , match} = this.props;
      let  flag = 0;
      let sendData= [
        {
          id: 0,
          empid:empid,
          appraiserid: 0,
          empcode: "",
          name: "",
          level: 0,
          weightage: 0
        }
      ]
      if (totalWeightage === 100){
        flag = 1;;
      } else{
        if(match.params.id&&tableData.length === 0 )
          flag = 2;
        else message.error(EmpConst.weAppShHun);
      }
      if(flag !== 0){
        await this.props.saveAppraiser(flag === 1? tableData:sendData);
        isAdded && this.props.setCount(true);
      }       
    } catch (error) {
      console.log(error);
    }
  };
  setTabEdit = (i) => {
    try {
      const { tableData, totalWeightage, selectData, empid } = this.state;
      const { selfapp, settingsDetails } = this.props;
      let data = tableData[i];
      let total = 0;
      let etDFL = {
        id: data.id,
        code: data.code,
        codeId: data.appraiserid,
        name: data.name,
      };
      selectData.push({
        id: data.appraiserid,
        value: data.code,
        name: data.name,
      });
      tableData.splice(i, 1);
      if (settingsDetails?.appraiserweight === 0) {
        let divid = 100 / tableData.length;
        tableData?.forEach((a, i) => {
          if (tableData.length - 1 !== i) {
            tableData[i].weightage = Math.floor(divid);
            total = total + Math.floor(divid);
          } else {
            tableData[i].weightage = 100 - total;
          }
        });
      }
      this.setState({
        initVal: etDFL,
        hierarchy: data.level,
        weightage: data.weightage,
        totalWeightage:
          settingsDetails?.appraiserweight === 0
            ? 100
            : totalWeightage - data.weightage,
        tableData: tableData,
        selectData,
      });
      if (selfapp && empid === data.appraiserid) {
        this.setState({ selfapp: selfapp });
      }
    } catch (error) {
      console.log(error);
    }
  };
  handleDelete = (a, i) => {
    try {
      const { tableData, totalWeightage, selectData, empid } = this.state;
      const { selfapp, settingsDetails } = this.props;
      let flag = empid === a.appraiserid ? selfapp : false;
      let total = 0;
      if (!flag) {
        selectData.push({
          id: a.appraiserid,
          value: a.code,
          name: a.name,
        });
        tableData.splice(i, 1);
        if (settingsDetails?.appraiserweight === 0) {
          let divid = 100 / tableData.length;
          tableData?.forEach((a, i) => {
            if (tableData.length - 1 !== i) {
              tableData[i].weightage = Math.floor(divid);
              total = total + Math.floor(divid);
            } else {
              tableData[i].weightage = 100 - total;
            }
          });
        }
        this.setState({
          totalWeightage:
            settingsDetails?.appraiserweight === 0
              ? 100
              : totalWeightage - a.weightage,
          tableData: tableData,
          selectData,
        });
      } else message.error("you Are Self Appraiser");
    } catch (error) {
      console.log(error);
    }
  };
  er = (r) => (r ? "empty" : "");
  render() {
    const {
      initVal,
      hierarchy,
      weightage,
      weightageError,
      totalWeightage,
      tableData,
      btnDisable,
    } = this.state;
    const { settingsDetails } = this.props;
    return (
      <>
        <Formik
          initialValues={initVal}
          validationSchema={AppraisalValidation}
          onSubmit={
            settingsDetails?.appraiserweight === 0
              ? this.subWithSetting
              : this.handleSubmit
          }
          enableReinitialize
        >
          {({
            values,
            errors,
            touched,
            onBlur,
            setFieldValue,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Row gutter={24} className="apraiserForm">
                <Col xs={24} sm={12} md={12} lg={6} xl={6}>
                  <div>
                    <Label
                      title={EmpConst.ec}
                      className={this.er(
                        errors.code && touched.code && values.code === ""
                      )}
                    />
                    {values.code === "" &&
                      this.selectUI(
                        "",
                        "code",
                        setFieldValue,
                        errors.code && touched.code
                      )}
                    {values.code !== "" &&
                      this.selectUI(values.code, "code", setFieldValue)}
                  </div>
                </Col>
                <Col xs={24} sm={12} md={12} lg={5} xl={5}>
                  <div>
                    <Label
                      title={EmpConst.name}
                      className={this.er(errors.name && touched.name)}
                    />
                    <Input
                      name="name"
                      onBlur={handleBlur}
                      value={values.name}
                      handleChange={handleChange}
                      className={this.er(errors.name && touched.name)}
                      disabled
                    />
                  </div>
                </Col>
                <Col xs={12} sm={8} md={8} lg={4} xl={4}>
                  <div>
                    <Label title={EmpConst.hie} />
                    <div className="countDiv">
                      <span className="actDiv">
                        <PlusCircleOutlined
                          onClick={() => this.handleHierarchy(true)}
                        />
                      </span>
                      <span className="actDiv lessMargin">{hierarchy}</span>
                      <span className="actDiv">
                        <MinusCircleOutlined
                          onClick={() => this.handleHierarchy(false)}
                        />
                      </span>
                    </div>
                  </div>
                </Col>
                {settingsDetails?.appraiserweight === 1 && (
                  <Col xs={12} sm={4} md={4} lg={3} xl={3}>
                    <div>
                      <Label
                        title={EmpConst.weight}
                        className={this.er(weightageError)}
                      />
                      <Input
                        number
                        defaultValue={1}
                        value={weightage}
                        className={weightageError ? "empty" : ""}
                        handleChange={this.handleWeight}
                      />
                    </div>
                  </Col>
                )}
                <div className="btnDiv">
                  <Button
                    type="submit"
                    disabled={
                      btnDisable ||
                      (totalWeightage > 100 && tableData.length > 0)
                    }
                  >
                    {ButtonConst.add}
                  </Button>
                  <Button
                  
                  >
                    {ButtonConst.clr}
                  </Button>
                </div>
              </Row>
              <div className="aprsTable">
                <table className="aprsDiv">
                  <thead>
                    <tr>
                      <th>{EmpConst.empCode}</th>
                      <th>{EmpConst.name}</th>
                      <th>{EmpConst.hie}</th>
                      {settingsDetails?.appraiserweight === 1 && (
                        <th>{EmpConst.weight}</th>
                      )}
                      <th>{""}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData.map((a, i) => (
                      <tr key={i}>
                        <td>{a.code}</td>
                        <td>{a.name}</td>
                        <td>{a.level}</td>
                        {settingsDetails?.appraiserweight === 1 && (
                          <td>{a.weightage}</td>
                        )}
                        <td className="actionTD">
                          <EditOutlined
                            onClick={() => {
                              values.codeId === 0 && this.setinitDfl();
                              values.codeId === 0 && this.setTabEdit(i);
                            }}
                          />
                          <DeleteOutlined
                            onClick={() => {
                              this.setinitDfl();
                              this.handleDelete(a, i);
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  {totalWeightage > 0 &&
                    tableData.length > 0 &&
                    settingsDetails?.appraiserweight === 1 && (
                      <tfoot>
                        <tr>
                          <td className="totalDiv">
                            {EmpConst.total + totalWeightage}
                          </td>
                        </tr>
                      </tfoot>
                    )}
                </table>
              </div>
            </Form>
          )}
        </Formik>

        <div className="btnDiv">
          <div className="btn anime">
            {/* <Button onClick={() => this.setCount()}>{ButtonConst.prev}</Button> */}
            <Button className="nextBtn" onClick={this.submitData}>
              {ButtonConst.next}
            </Button>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  userList: state.organization.userList,
  isAdded: state.employee.isAdded,
  appraiserDetail: state.employee.appraiserDetail,
  settingsDetails: state.organization.settingsDetails,
});
const mapDispatchToProps = (dispatch) => ({
  getUserListByOrgId: (id) => dispatch(getUserListByOrgId(id)),
  saveAppraiser: (payload) => dispatch(saveAppraiser(payload)),
  getAppraiser: (id) => dispatch(getAppraiser(id)),
  getSettings: (id) => dispatch(getSettings(id)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Appraiser)
);
