import styled from "styled-components";
import { size } from "App/device";

const JrAppContainer = styled.div`
  // .collHead{
  //   display: flex;
  //   align-items: center;
  //   .nameText{
  //     margin-right:1em;
  //   }
  //   @media ${size["mobile-md-max"]} {
  //     display: block;
  //   }
  // }
  .ant-empty {
    padding: 1em 0;
    box-shadow: 0px 0px 7px rgb(0 0 0 / 15%);
  }
  .ant-collapse-content-box {
    overflow-x: auto;
    table {
      border-collapse: collapse;
      border-spacing: 0;
      width: 100%;
      border: 1px solid #ddd;
      margin-top: 1em;
      margin-bottom: 1em;
      @media ${size["tablet-max"]} {
        min-width: 52em;
      }
      th,
      td {
        text-align: left;
        padding: 16px;
      }
      thead {
        background-color: #f2f2f2;
      }
      tbody tr:nth-child(even) {
        background-color: #fafafa;
      }
      .sizDiv {
        width: 10em;
      }
      .anticon-download {
        font-size: 17px;
        cursor: pointer;
      }
    }
  }
  .remarkDiv {
    min-height: 7em;
    box-shadow: 0px 0px 7px rgb(0 0 0 / 15%);
    border-radius: 10px;
    margin-top: 1em;
    padding: 0.5em 1em;
  }
`;
export { JrAppContainer };
