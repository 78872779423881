import React, { Component } from "react";
import * as Yup from "yup";
import { Modal } from "antd";
import { connect } from "react-redux";
import { Formik, Form } from "formik";
import { message } from "antd";
import { withRouter } from "react-router-dom";

import { EmpConst } from "./../constant";
import { getKRA } from "redux/master/action";
import { getOrgId } from "modules/helper";
import { ButtonConst } from "App/AppConstant";
import { Select, Input, Button, Label, AutoComplete } from "components/Form";
var orgID = getOrgId();

const initValidation = Yup.object().shape({
  kra: Yup.string().trim().required(" "),
  kpi: Yup.string().trim().required(" "),
});

class AddModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      btnDisable: false,
      kpiWeightage: 0,
      autoData: [],
      initVal: {
        index: null,
        id: 0,
        kra: "",
        kraID: 0,
        kpi: "",
      },
    };
  }
  async componentDidMount() {
    try {
      const { editData } = this.props;
      orgID = getOrgId();
      await this.props.getKRA(orgID);
      if (editData.edit === true && editData.index !== null) {
        let init = {
          index: editData.index,
          id: editData.id,
          kra: editData.kra,
          kraID: editData.kraID,
          kpi: editData.kpi,
        };
        this.setautoData(editData.kraID);
        this.setState({
          kpiWeightage: editData.kpiWeightage,
          initVal: init,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }
  setautoData = (id) => {
    try {
      const { kra } = this.props;
      let data = kra?.find((x) => x.kraid === id).kpiArrays;
      let auOption = [];
      data?.forEach((a, i) => {
        auOption.push({ value: a.kpiname });
      });
      this.setState({ autoData: auOption });
    } catch (error) {
      console.log(error);
    }
  };
  handleSubmit = (values, { setSubmitting }) => {
    try {
      const { kpiWeightage } = this.state;
      const { collapsArra, selectedKra } = this.props;
      this.setState({ btnDisable: true });
      setTimeout(() => this.setState({ btnDisable: false }), 4500);
      let findData = collapsArra.find((x) => x.selectTypeID === selectedKra);
      let flag = false;
      let total = findData?.kpiTableData?.length === 0 ? kpiWeightage : 0;
      if (kpiWeightage === 0) {
        flag = true;
        message.error("Add KPI Weightage !");
      }
      if (findData?.kpiTableData?.length > 0) {
        findData?.kpiTableData?.forEach((a, i) => {
          total = total + a.kpiWeightage;
        });
      }
      if (values.index !== null) {
        let findWeight = findData?.kpiTableData[values.index];
        total = total - findWeight.kpiWeightage + kpiWeightage;
      } else if (findData?.kpiTableData?.length > 0)
        total = total + kpiWeightage;
      if (total > 100) {
        flag = true;
        message.error("Total weightage of Kpi shoud be 100%");
      }
      if (!flag) {
        let sendData = {
          index: values.index,
          selectedKra: selectedKra,
          toKpiWeight: total,
          id: values.id,
          kpi: values.kpi.trim(),
          kra: values.kra,
          kraID: values.kraID,
          kpiWeightage: kpiWeightage,
        };
        this.props.getSubmit(sendData);
      }
      setSubmitting(false);
    } catch (error) {
      console.log(error);
    }
  };
  handleKPIWgh = (val) => this.setState({ kpiWeightage: val });
  selectUI = (id, val, name, setFieldValue, error) => {
    try {
      return (
        <Select
          data={this.getSelectData(id)}
          value={val}
          defaultValue={val}
          withID
          inModal="modal-formDrop"
          className={this.er(error)}
          onChange={(value, data) => {
            setFieldValue(name, value);
            setFieldValue("kraID", data.id);
            setFieldValue("kpi", "");
            this.setautoData(data.id);
          }}
        />
      );
    } catch (error) {
      console.log(error);
    }
  };
  getSelectData = (id) => {
    try {
      const { kra } = this.props;
      let data = [];
      kra?.forEach((a, i) => {
        if (id === a.kratypeid) {
          data.push({ id: a.kraid, value: a.kraname });
        }
      });
      return data;
    } catch (error) {
      console.log(error);
    }
  };
  er = (r) => (r ? "empty" : "");
  render() {
    const { initVal, kpiWeightage, btnDisable, autoData } = this.state;
    const { onOk, onCancel, selectedKra } = this.props;
    return (
      <Modal
        visible={true}
        onOk={onOk}
        onCancel={onCancel}
        footer={null}
        title={`ADD KPI`}
        getContainer={() => document.getElementById("addModal-form")}
        className="addModalForm"
        centered
      >
        <Formik
          initialValues={initVal}
          validationSchema={initValidation}
          onSubmit={this.handleSubmit}
          enableReinitialize
        >
          {({
            values,
            errors,
            touched,
            onBlur,
            setFieldValue,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <Form onSubmit={handleSubmit} id="modal-formDrop">
              <Label
                title={EmpConst.kra}
                className={this.er(
                  errors.kra && touched.kra && values.kra === ""
                )}
              />
              {values.kra === "" &&
                this.selectUI(
                  selectedKra,
                  "",
                  "kra",
                  setFieldValue,
                  errors.kra && touched.kra
                )}
              {values.kra !== "" &&
                this.selectUI(selectedKra, values.kra, "kra", setFieldValue)}
              <Label
                title={EmpConst.kpi}
                className={this.er(errors.kpi && touched.kpi)}
              />
              {/* <Input
                name="kpi"
                value={values.kpi}
                onBlur={handleBlur}
                handleChange={handleChange}
                className={errors.kpi && touched.kpi ? "empty" : ""}
              /> */}
              <AutoComplete
                onBlur={handleBlur}
                name="kpi"
                value={values.kpi}
                handleChange={(val) => setFieldValue("kpi", val)}
                selectData={autoData}
                disabled={values.kraID === 0}
                className={errors.kpi && touched.kpi ? "autoempty" : ""}
              />
              <Label title={EmpConst.weight} className="weight" />
              <Input
                number
                value={kpiWeightage}
                handleChange={(val) => this.handleKPIWgh(val)}
              />
              <div className="btnDiv">
                <div className="btn">
                  <Button type="submit" disabled={btnDisable}>
                    {ButtonConst.submit}
                  </Button>
                  <Button onClick={onCancel}>{ButtonConst.cancel}</Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  kra: state.master.kra,
});
const mapDispatchToProps = (dispatch) => ({
  getKRA: (id) => dispatch(getKRA(id)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddModal)
);
