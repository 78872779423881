import React, { Component } from "react";
import * as Yup from "yup";
import { Row, Col } from "antd";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Formik, Form } from "formik";

import { getMaster } from "redux/master/action";
import { EmpConst, gender, role } from "./../constant";
import { getOrgId, getAuthUserID } from "modules/helper";
import { getUserListByOrgId } from "redux/organization/action";
import { saveEmployee, getEmployeeByID } from "redux/employee/action";
import { ButtonConst, FormValidation, pwdMatch } from "App/AppConstant.js";
import { Label, Input, Button, Select, Checkbox } from "components/Form";

var orgID = getOrgId();
var userID = getAuthUserID();
const EmployeeValidation = Yup.object().shape({
  empcode: Yup.string().trim().required(" "),
  firstname: Yup.string()
    .trim()
    .matches(/^[aA-zZ0-9 ]*$/)
    .required(" "),
  middlename: Yup.string()
    .trim()
    .matches(/^[aA-zZ0-9 ]*$/),
  lastname: Yup.string()
    .trim()
    .matches(/^[aA-zZ0-9 ]*$/)
    .required(" "),
  gender: Yup.string().trim().required(" "),
  email: Yup.string().trim().email().required(" "),
  mobile: Yup.string()
    .trim()
    .min(10, FormValidation.mobileInvalid)
    .max(10, FormValidation.mobileInvalid)
    .required(" "),
  department: Yup.string().trim().required(" "),
  designation: Yup.string().trim().required(" "),
  role: Yup.string().trim().required(" "),
  pwd: Yup.string()
    .trim()
    .matches(pwdMatch, FormValidation.passwordInvalid)
    .required(" "),
});

class Employee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      btnDisable: false,
      selfapp: false,
      desigData: [],
      departData: [],
      locaData: [],
      zonedata: [],
      verticalData: [],
      repoToData: [],
      initVal: {
        empid: 0,
        empcode: "",
        firstname: "",
        middlename: "",
        lastname: "",
        gender: "",
        genderid: 0,
        department: "",
        departmentid: 0,
        designation: "",
        designationid: 0,
        mobile: "",
        email: "",
        report: "",
        reportid: 0,
        location: "",
        locationid: 0,
        zone: "",
        zoneid: 0,
        vertical: "",
        verticalid: 0,
        role: "",
        roleid: 0,
        pwd: "",
        onlySave: false,
      },
    };
  }
  async componentDidMount() {
    try {
      const { match } = this.props;
      orgID = getOrgId();
      userID = userID ? userID : getAuthUserID();
      await this.props.getMaster(0 + "/" + orgID);
      await this.props.getUserListByOrgId(orgID + "/" + 0);
      if (match.params.id) {
        let id = window.atob(match.params.id);
        await this.props.getEmployeeByID(id);
      }
    } catch (error) {
      console.log(error);
    }
  }
  async componentDidUpdate(prevProps) {
    try {
      const { desigData, departData, locaData, zonedata, verticalData } =
        this.state;
      const { master, userList, employeeDetails } = this.props;
      if (master !== prevProps.master) {
        master?.forEach((a, i) => {
          if (a.mtid === 1) {
            desigData.push({ id: a.mid, value: a.mname });
            this.setState({ desigData: desigData });
          } else if (a.mtid === 2) {
            departData.push({ id: a.mid, value: a.mname });
            this.setState({ department: departData });
          } else if (a.mtid === 3) {
            locaData.push({ id: a.mid, value: a.mname });
            this.setState({ locaData: locaData });
          } else if (a.mtid === 4) {
            zonedata.push({ id: a.mid, value: a.mname });
            this.setState({ zonedata: zonedata });
          } else if (a.mtid === 5) {
            verticalData.push({ id: a.mid, value: a.mname });
            this.setState({ verticalData: verticalData });
          }
        });
      }
      if (userList !== prevProps.userList) {
        let data = [];
        userList?.forEach((a, i) => {
          data.push({ id: a.userid, value: a.fullname });
        });

        this.setState({ repoToData: data });
      }
      if (employeeDetails !== prevProps.employeeDetails) {
        this.setDefault(employeeDetails);
      }
    } catch (error) {
      console.log(error);
    }
  }
  setDefault = (data) => {
    try {
      const {
        desigData,
        departData,
        locaData,
        zonedata,
        verticalData,
        repoToData,
      } = this.state;
      let dptdata = departData.find((x) => x.id === data.departmentid);
      let desdata = desigData.find((x) => x.id === data.designationid);
      let repdata = repoToData.find((x) => x.id === data.reportto);
      let locdata = locaData.find((x) => x.id === data.locationid);
      let zdata = zonedata.find((x) => x.id === data.zoneid);
      let vtcdata = verticalData.find((x) => x.id === data.verticalid);
      let editData = {
        empid: data.empid,
        empcode: data.empcode,
        firstname: data.firstname,
        middlename: data.middlename ? data.middlename : "",
        lastname: data.lastname ? data.lastname : "",
        gender: gender.find((x) => x.id === data.gender).value,
        genderid: data.gender,
        department: dptdata ? dptdata.value : "",
        departmentid: dptdata ? dptdata.id : 0,
        designation: desdata ? desdata.value : "",
        designationid: desdata ? desdata.id : 0,
        mobile: data.mobileno,
        email: data.email,
        report: data.reportto && repdata ? repdata.value : "",
        reportid: data.reportto && repdata ? repdata.id : 0,
        location: data.locationid && locdata ? locdata.value : "",
        locationid: data.locationid && locdata ? locdata.id : 0,
        zone: data.zoneid && zdata ? zdata.value : "",
        zoneid: data.zoneid && zdata ? zdata.id : 0,
        vertical: data.verticalid && vtcdata ? vtcdata.value : "",
        verticalid: data.verticalid && vtcdata ? vtcdata.id : 0,
        role: role.find((x) => x.id === data.isadmin).value,
        roleid: data.isadmin,
        pwd: data.password,
      };
      this.setState({
        selfapp: data.allowselfappraisal === 1,
        initVal: editData,
      });
    } catch (error) {
      console.log(error);
    }
  };
  selectUI = (val, name, setFieldValue, error) => {
    try {
      const {
        desigData,
        departData,
        locaData,
        zonedata,
        verticalData,
        repoToData,
      } = this.state;
      return (
        <Select
          data={
            name === "gender"
              ? gender
              : name === "department"
              ? departData
              : name === "designation"
              ? desigData
              : name === "report"
              ? repoToData
              : name === "location"
              ? locaData
              : name === "zone"
              ? zonedata
              : name === "vertical"
              ? verticalData
              : role
          }
          inModal="emp-Select"
          value={val}
          defaultValue={val}
          withID
          className={error ? "empty" : ""}
          onChange={(value, data) => {
            setFieldValue(name, value);
            if (name === "gender") setFieldValue("genderid", data.id);
            else if (name === "department")
              setFieldValue("departmentid", data.id);
            else if (name === "designation")
              setFieldValue("designationid", data.id);
            else if (name === "report") setFieldValue("reportid", data.id);
            else if (name === "location") setFieldValue("locationid", data.id);
            else if (name === "zone") setFieldValue("zoneid", data.id);
            else if (name === "vertical") setFieldValue("verticalid", data.id);
            else if (name === "role") setFieldValue("roleid", data.id);
          }}
        />
      );
    } catch (error) {
      console.log(error);
    }
  };
  handleCheck = (val) => {
    try {
      const { selfapp } = this.state;
      this.setState({ selfapp: !selfapp });
    } catch (error) {
      console.log(error);
    }
  };
  er = (r) => (r ? "empty" : "");
  handleSubmit = async (values, { setSubmitting }) => {
    try {
      const { selfapp } = this.state;
      this.setState({ btnDisable: true });
      setTimeout(() => {
        this.setState({ btnDisable: false });
      }, 4500);
      let postData = {
        empid: values.empid,
        empcode: values.empcode.trim(),
        firstname: values.firstname.trim(),
        middlename: values.middlename.trim(),
        lastname: values.lastname.trim(),
        gender: values.genderid,
        departmentid: values.departmentid,
        designationid: values.designationid,
        mobileno: values.mobile.toString(),
        email: values.email.trim(),
        reportto: values.reportid,
        locationid: values.locationid,
        zoneid: values.zoneid,
        verticalid: values.verticalid,
        password: values.pwd.trim(),
        allowselfappraisal: selfapp ? 1 : 0,
        isadmin: values.roleid,
        orgid: orgID,
        createdby: userID,
      };
      await this.props.saveEmployee(postData);
      this.redirect(values.onlySave, selfapp);
      setSubmitting(false);
    } catch (error) {
      console.log(error);
    }
  };
  redirect = async (val, selfapp) => {
    try {
      const { isAdded } = this.props;
      if (val && isAdded) this.props.history.push("/employee");
      else {
        !val && isAdded && this.props.setCount(true, selfapp);
      }
    } catch (error) {
      console.log(error);
    }
  };
  render() {
    const { match } = this.props;
    const { initVal, selfapp, btnDisable } = this.state;
    return (
      <Formik
        initialValues={initVal}
        validationSchema={EmployeeValidation}
        onSubmit={this.handleSubmit}
        enableReinitialize
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit} id="emp-Select">
            <Row gutter={24}>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <div>
                  <Label
                    title={EmpConst.ec}
                    className={this.er(errors.empcode && touched.empcode)}
                  />
                  <Input
                    name="empcode"
                    onBlur={handleBlur}
                    value={values.empcode}
                    handleChange={handleChange}
                    className={this.er(errors.empcode && touched.empcode)}
                  />
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <div>
                  <Label
                    title={EmpConst.fname}
                    className={
                      errors.firstname && touched.firstname ? "empty" : ""
                    }
                  />
                  <Input
                    name="firstname"
                    onBlur={handleBlur}
                    value={values.firstname}
                    handleChange={handleChange}
                    className={
                      errors.firstname && touched.firstname ? "empty" : ""
                    }
                  />
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <div>
                  <Label
                    title={EmpConst.mname}
                    className={
                      errors.middlename && touched.middlename ? "empty" : ""
                    }
                  />
                  <Input
                    name="middlename"
                    onBlur={handleBlur}
                    value={values.middlename}
                    handleChange={handleChange}
                    className={
                      errors.middlename && touched.middlename ? "empty" : ""
                    }
                  />
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <div>
                  <Label
                    title={EmpConst.lname}
                    className={
                      errors.lastname && touched.lastname ? "empty" : ""
                    }
                  />
                  <Input
                    name="lastname"
                    onBlur={handleBlur}
                    value={values.lastname}
                    handleChange={handleChange}
                    className={
                      errors.lastname && touched.lastname ? "empty" : ""
                    }
                  />
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <div>
                  <Label
                    title={EmpConst.gender}
                    className={
                      errors.gender && touched.gender && values.gender === ""
                        ? "empty"
                        : ""
                    }
                  />
                  {values.gender === "" &&
                    this.selectUI(
                      "",
                      "gender",
                      setFieldValue,
                      errors.gender && touched.gender
                    )}
                  {values.gender !== "" &&
                    this.selectUI(values.gender, "gender", setFieldValue)}
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <div>
                  <Label
                    title={EmpConst.depart}
                    className={
                      errors.department &&
                      touched.department &&
                      values.department === ""
                        ? "empty"
                        : ""
                    }
                  />
                  {values.department === "" &&
                    this.selectUI(
                      "",
                      "department",
                      setFieldValue,
                      errors.department && touched.department
                    )}
                  {values.department !== "" &&
                    this.selectUI(
                      values.department,
                      "department",
                      setFieldValue
                    )}
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <div>
                  <Label
                    title={EmpConst.desi}
                    className={this.er(
                      errors.designation &&
                        touched.designation &&
                        values.designation === ""
                        ? "empty"
                        : ""
                    )}
                  />
                  {values.designation === "" &&
                    this.selectUI(
                      "",
                      "designation",
                      setFieldValue,
                      errors.designation && touched.designation
                    )}
                  {values.designation !== "" &&
                    this.selectUI(
                      values.designation,
                      "designation",
                      setFieldValue
                    )}
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <div>
                  <Label
                    title={EmpConst.mob}
                    className={this.er(errors.mobile && touched.mobile)}
                  />
                  <Input
                    name="mobile"
                    type="number"
                    onBlur={handleBlur}
                    value={values.mobile}
                    handleChange={handleChange}
                    className={this.er(errors.mobile && touched.mobile)}
                  />
                  {errors.mobile && touched.mobile && (
                    <div className="form-error">{errors.mobile}</div>
                  )}
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <div>
                  <Label
                    title={EmpConst.email}
                    className={errors.email && touched.email ? "empty" : ""}
                  />
                  <Input
                    name="email"
                    onBlur={handleBlur}
                    value={values.email}
                    handleChange={handleChange}
                    className={errors.email && touched.email ? "empty" : ""}
                  />
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <Label
                  title={EmpConst.role}
                  className={this.er(
                    errors.role && touched.role && values.role === ""
                      ? "empty"
                      : ""
                  )}
                />
                {values.role === "" &&
                  this.selectUI(
                    "",
                    "role",
                    setFieldValue,
                    errors.role && touched.role
                  )}
                {values.role !== "" &&
                  this.selectUI(values.role, "role", setFieldValue)}
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <div>
                  <Label title={EmpConst.report} />
                  {values.report === "" &&
                    this.selectUI("", "report", setFieldValue)}
                  {values.report !== "" &&
                    this.selectUI(values.report, "report", setFieldValue)}
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <div>
                  <Label title={EmpConst.location} />
                  {values.location === "" &&
                    this.selectUI("", "location", setFieldValue)}
                  {values.location !== "" &&
                    this.selectUI(values.location, "location", setFieldValue)}
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <div>
                  <Label title={EmpConst.zone} />
                  {values.zone === "" &&
                    this.selectUI("", "zone", setFieldValue)}
                  {values.zone !== "" &&
                    this.selectUI(values.zone, "zone", setFieldValue)}
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <div>
                  <Label title={EmpConst.vertical} />
                  {values.vertical === "" &&
                    this.selectUI("", "vertical", setFieldValue)}
                  {values.vertical !== "" &&
                    this.selectUI(values.vertical, "vertical", setFieldValue)}
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <div>
                  <Label
                    title={EmpConst.pwd}
                    className={errors.pwd && touched.pwd ? "empty" : ""}
                  />
                  <Input
                    password
                    name="pwd"
                    onBlur={handleBlur}
                    value={values.pwd}
                    handleChange={handleChange}
                    className={errors.pwd && touched.pwd ? "empty" : ""}
                  />
                  {errors.pwd && touched.pwd && (
                    <div className="form-error">{errors.pwd}</div>
                  )}
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <div className="chekBoxDIv">
                  <Checkbox
                    text={EmpConst.selfapp}
                    checked={selfapp}
                    onChange={() => this.handleCheck()}
                  />
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <div className="btnDiv">
                  <div className="btn anime">
                    {(!selfapp && !match.params.id)&& (
                      <Button
                        disabled={btnDisable}
                        onClick={() => {
                          setFieldValue("onlySave", true);
                          handleSubmit();
                        }}
                      >
                        {ButtonConst.save}
                      </Button>
                    )}
                    <Button
                      disabled={btnDisable}
                      onClick={() => {
                        setFieldValue("onlySave", false);
                        handleSubmit();
                      }}
                    >
                      {selfapp ? ButtonConst.next : ButtonConst.snext}
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    );
  }
}
const mapStateToProps = (state) => ({
  master: state.master.master,
  userList: state.organization.userList,
  employeeDetails: state.employee.employeeDetails,
  isAdded: state.employee.isAdded,
});
const mapDispatchToProps = (dispatch) => ({
  getMaster: (id) => dispatch(getMaster(id)),
  getUserListByOrgId: (id) => dispatch(getUserListByOrgId(id)),
  saveEmployee: (payload) => dispatch(saveEmployee(payload)),
  getEmployeeByID: (id) => dispatch(getEmployeeByID(id)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Employee)
);
