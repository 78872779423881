import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";

import { RepViewStyle } from "./style";
import  ViewDetPage from "./ViewDetail";
import { reportConst } from "./constant";
import { getScoreById } from "redux/employee/action";
import { Row, Col } from "components/Form";
import { getOrgId ,getAuthRole } from "modules/helper";
import { getSettings  } from "redux/organization/action";

var orgID = getOrgId();
var role = getAuthRole();

class ReportsView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uiData: [],
      viewDetail:0,
    };
  }
  async componentDidMount() {
    try {
      const { match } = this.props;
      orgID =  getOrgId();
      role = getAuthRole();
      let locData = JSON.parse(localStorage.yearAndPeriod);
      await this.props.getSettings(orgID);
      await this.props.getScoreById(
        locData.yerID +
          "/" +
          locData.periodID +
          "/" +
          parseInt(window.atob(match.params.id))
      );
    } catch (error) {
      console.log(error);
    }
  }
  trUI = (a, b) => {
    try {
      return (
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <div className="title">{a}</div>
          <div className="valueDiv">{b}</div>
        </Col>
      );
    } catch (error) {
      console.log(error);
    }
  };
  setView=(val)=> this.setState({viewDetail:val})
  render() {
    const { scoreById ,settingsDetails} = this.props;
    const {viewDetail} = this.state;
    return (
      <RepViewStyle>
        {viewDetail === 0?<div className="secDiv">
          <div className="headDiv">
            <h2>{reportConst.repView}</h2>
            <div
              className="btnDiv"
              onClick={() => this.props.history.push("/report")}
            >
              <LeftOutlined />
            </div>
          </div>
          <div className="pDiv">
          <Row gutter={24}>
          {this.trUI(
                reportConst.empCode + reportConst.colon,
                scoreById.empcode
              )}
          {this.trUI(
                reportConst.revFor + reportConst.colon,
                scoreById.reviewperiod
              )}
          {this.trUI(
                reportConst.empName + reportConst.colon,
                scoreById.empname
              )}
          {this.trUI(
                reportConst.repPers + reportConst.colon,
                scoreById.reportperson
              )}
          {this.trUI(
                reportConst.depa + reportConst.colon,
                scoreById.department
              )}
          {this.trUI(
                reportConst.des + reportConst.colon,
                scoreById.designation
              )}
          {this.trUI(
                reportConst.revBY + reportConst.colon,
                scoreById.reviewby
              )}
          </Row>
          <h2 className="summHead anime">{reportConst.sumReport}</h2>
            {scoreById?.kraTypeWeights?.map((a) => (
              <>
               <div className="flexDiv">
                    <h4 className="kraText">{a.kratypename}</h4>
                    {settingsDetails.kratypeweight === 1 && ( 
                      <h4>{`${reportConst.weight} : ${a.kratypeweight}`}</h4>
                   )}
                  </div>
                {/* <div className="titleText">{a.kratypename}</div> */}
                <hr />
                <div className="tableDiv">
                  <table>
                    <thead>
                      <tr>
                        <th>{reportConst.kra}</th>
                        <th>{reportConst.kpi}</th>
                        {role !== 4&&<th>{reportConst.weight}</th>}
                        <th>{reportConst.obtScore}</th>
                        <th>{reportConst.maxObtScore}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {a.kpiWeights?.map((x, inx) => (
                        <tr key={inx}>
                          <td>{x.kraname}</td>
                          <td>{x.kpiname}</td>
                          {role !== 4&&<td>{x.kpiweight}</td>}
                          <td>{x.totalkpiscore}</td>
                          <td>{x.maxkpiscorecanget}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </>
            ))}
            <div className="bottomDiv">{`${reportConst.finalSco} ${
              scoreById.totalscore + "/" + scoreById.maxscore
            }`}
             {role !== 4 &&<p onClick={()=>this.setView(1)}>{reportConst.viDe}</p>}
            </div>
          </div>
        </div>:<ViewDetPage setView={this.setView} name={scoreById.empname}/>}
      </RepViewStyle>
    );
  }
}

const mapStateToProps = (state) => ({
  scoreById: state.employee.scoreById,
  settingsDetails: state.organization.settingsDetails,

});
const mapDispatchToProps = (dispatch) => ({
  getScoreById: (id) => dispatch(getScoreById(id)),
  getSettings: (id) => dispatch(getSettings(id)),

});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ReportsView)
);
