import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, message } from "antd";
import { withRouter } from "react-router-dom";
import {
  PlusOutlined,
  DeleteOutlined,
  EditOutlined,
  DownOutlined,
  UpOutlined,
} from "@ant-design/icons";

import KpiModal from "./KpiModal";
import { EmpConst } from "../constant";
import { getOrgId } from "modules/helper";
import { ButtonConst } from "App/AppConstant";
import { getMaster } from "redux/master/action";
import { getSettings } from "redux/organization/action";
import { Select, Input, Button } from "components/Form";
import { saveWeightage, getWeightage } from "redux/employee/action";
var orgID = getOrgId();

class KraKpi extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitDis: false,
      empid: 0,
      wid: 0,
      weightage: 0,
      weightageError: false,
      selectType: "",
      selectTypeID: 0,
      selectTypeError: false,
      selectDisable: false,
      indexID: null,
      kraTypeData: [],
      collapsArra: [],
      allCollectData: [],
      totalWeightage: 0,
      showcoll: 0,
      addModal: false,
      kraId: null,
      editData: {},
    };
  }
  async componentDidMount() {
    try {
      const { match } = this.props;
      orgID = getOrgId();
      let empid = JSON.parse(localStorage.employeeId);
      this.setState({ empid: empid.empId });
      await this.props.getSettings(orgID);
      await this.props.getMaster(6 + "/" + orgID);
      match.params.id &&
        (await this.props.getWeightage(parseInt(window.atob(match.params.id))));
    } catch (error) {
      console.log(error);
    }
  }
  componentDidUpdate(prevProps) {
    try {
      const { master, weightageData } = this.props;
      if (master !== prevProps.master) {
        let collectData = [];
        master?.forEach((e, i) => {
          collectData.push({ id: e.mid, value: e.mname });
        });
        this.setState({ kraTypeData: collectData });
      }
      if (weightageData !== prevProps.weightageData) {
        let colArray = [];
        let total = 0;
        weightageData?.forEach((a, i) => {
          total = total + a.kratypeweight;
          let kraData = {
            wid: a.wid,
            empid: a.empid,
            weightage: a.kratypeweight,
            selectType: a.kratypename,
            selectTypeID: a.kratypeid,
            toKpiWeight: 0,
            kpiTableData: [],
          };
          a.kpiweightages?.forEach((x, i) => {
            kraData.toKpiWeight = kraData.toKpiWeight + x.kpiweight;
            kraData?.kpiTableData.push({
              id: x.kid,
              kpi: x.kpiname,
              kra: x.kraname,
              kraID: x.kraid,
              kpiWeightage: x.kpiweight,
            });
          });
          colArray.push(kraData);
        });
        this.setSelect(colArray);
        this.setState({
          collapsArra: colArray,
          totalWeightage: total,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }
  setSelect = (collapsArra) => {
    try {
      const { kraTypeData } = this.state;
      let sData = [];
      kraTypeData?.forEach((x, i) => {
        let chek = collapsArra.findIndex((a) => a.selectTypeID === x.id);
        if (chek === -1) {
          sData.push(x);
        }
      });
      this.setState({ kraTypeData: sData });
    } catch (error) {
      console.log(error);
    }
  };
  handleWeight = (val) => {
    try {
      const { totalWeightage } = this.state;
      let total = 0;
      total = totalWeightage + val;
      this.setState({
        weightage: val,
        weightageError: val > 0 && total <= 100 ? false : true,
      });
    } catch (error) {
      console.log(error);
    }
  };
  handleClear = () => {
    try {
      const { indexID, collapsArra, totalWeightage } = this.state;
      let data = collapsArra[indexID];
      collapsArra[indexID] = data;
      this.setState({
        collapsArra,
        selectDisable: false,
        indexID: null,
        totalWeightage: totalWeightage + data.weightage,
      });
      this.setCollDFL();
    } catch (error) {
      console.log(error);
    }
  };
  selectAddUI = (val, error) => {
    try {
      const { kraTypeData, selectDisable } = this.state;
      return (
        <Select
          data={kraTypeData}
          value={val}
          defaultValue={val}
          withID
          disabled={selectDisable}
          className={error ? "empty" : ""}
          onChange={(value, data) => {
            this.setState({
              selectType: value,
              selectTypeID: data.id,
              selectTypeError: false,
            });
          }}
        />
      );
    } catch (error) {
      console.log(error);
    }
  };
  handleAdd = () => {
    try {
      const {
        weightage,
        totalWeightage,
        selectType,
        selectTypeID,
        collapsArra,
        kraTypeData,
        indexID,
        wid,
        empid,
      } = this.state;
      this.setState({ btnDisable: true });
      setTimeout(() => this.setState({ btnDisable: false }), 4500);
      let sortselect = [];
      let flag = false;
      let total = totalWeightage;
      total = totalWeightage + weightage;
      if (selectTypeID === 0) {
        flag = true;
        this.setState({ selectTypeError: true });
      }
      if (weightage <= 0) {
        flag = true;
        this.setState({ weightageError: true });
      }
      if (total > 100) {
        flag = true;
        this.setState({ weightageError: true });
      }
      if (!flag) {
        if (indexID === null) {
          let addData = {
            wid: wid,
            empid: empid,
            weightage: weightage,
            selectType: selectType,
            selectTypeID: selectTypeID,
            toKpiWeight: 0,
            kpiTableData: [],
          };
          collapsArra.push(addData);
        } else {
          collapsArra[indexID].wid = wid;
          collapsArra[indexID].empid = empid;
          collapsArra[indexID].weightage = weightage;
          collapsArra[indexID].selectType = selectType;
          collapsArra[indexID].selectTypeID = selectTypeID;
        }
        kraTypeData?.forEach((a, i) => {
          let ckeck = collapsArra.find((x) => x.selectTypeID === a.id);
          !ckeck && sortselect.push(a);
        });
        this.setCollDFL();
        this.setState({
          collapsArra,
          totalWeightage: total,
          kraTypeData: sortselect,
          indexID: null,
          selectDisable: false,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  withAdd = () => {
    const { selectType, selectTypeID, collapsArra, kraTypeData, wid, empid } =
      this.state;
    this.setState({ btnDisable: true });
    setTimeout(() => this.setState({ btnDisable: false }), 4500);
    let sortselect = [];
    let flag = false;
    let wight = collapsArra.length > 0 ? 0 : 100;
    let total = 0;
    let divid = collapsArra.length > 0 ? 100 / (collapsArra.length + 1) : 100;
    collapsArra?.forEach((a, i) => {
      collapsArra[i].weightage = Math.floor(divid);
      total = total + Math.floor(divid);
    });
    wight = collapsArra.length > 0 ? 100 - total : wight;
    total = total + (100 - total);
    if (total > 100) {
      flag = true;
    }
    if (!flag) {
      let addData = {
        wid: wid,
        empid: empid,
        weightage: wight,
        selectType: selectType,
        selectTypeID: selectTypeID,
        toKpiWeight: 0,
        kpiTableData: [],
      };
      collapsArra.push(addData);
      kraTypeData?.forEach((a, i) => {
        let ckeck = collapsArra.find((x) => x.selectTypeID === a.id);
        !ckeck && sortselect.push(a);
      });
      this.setCollDFL();
      this.setState({
        collapsArra,
        totalWeightage: total,
        kraTypeData: sortselect,
        indexID: null,
        selectDisable: false,
      });
    }
  };
  setCollDFL = () => {
    try {
      this.setState({
        wid: 0,
        weightage: 0,
        selectType: "",
        selectTypeID: 0,
      });
    } catch (error) {
      console.log(error);
    }
  };
  setCollaps = (i) => {
    try {
      const { showcoll } = this.state;
      this.setState({ showcoll: showcoll === i ? null : i });
    } catch (error) {
      console.log(error);
    }
  };
  setEditColl = (a, i) => {
    try {
      const { totalWeightage, kraTypeData } = this.state;
      kraTypeData.push({ id: a.selectTypeID, value: a.selectType });
      this.setState({
        wid: a.wid,
        weightage: a.weightage,
        selectType: a.selectType,
        selectTypeID: a.selectTypeID,
        selectDisable: true,
        indexID: i,
        totalWeightage: totalWeightage - a.weightage,
        kraTypeData,
      });
    } catch (error) {
      console.log(error);
    }
  };
  removeColl = (a, i) => {
    try {
      const { collapsArra, totalWeightage, kraTypeData } = this.state;
      kraTypeData.push({ id: a.selectTypeID, value: a.selectType });
      collapsArra.splice(i, 1);
      this.setState({
        selectDisable: false,
        indexID: null,
        totalWeightage: totalWeightage - a.weightage,
        kraTypeData,
        collapsArra,
      });
    } catch (error) {
      console.log(error);
    }
  };
  withColl = (a, i) => {
    try {
      const { collapsArra, kraTypeData } = this.state;
      kraTypeData.push({ id: a.selectTypeID, value: a.selectType });
      collapsArra.splice(i, 1);
      let total = 0;
      let divid = 100 / collapsArra.length;
      collapsArra?.forEach((a, i) => {
        if (collapsArra.length - 1 !== i) {
          collapsArra[i].weightage = Math.floor(divid);
          total = total + Math.floor(divid);
        } else {
          collapsArra[i].weightage = 100 - total;
          total = total + (100 - total);
        }
      });
      this.setState({
        selectDisable: false,
        indexID: null,
        totalWeightage: total,
        kraTypeData,
        collapsArra,
      });
    } catch (error) {
      console.log(error);
    }
  };
  headerUI = (a, i) => {
    try {
      const { showcoll } = this.state;
      const { settingsDetails } = this.props;
      return (
        <div className="collheader">
          <div className="textDiv" onClick={() => this.setCollaps(i)}>
            <h4>{a.selectType}</h4>
            {settingsDetails.kratypeweight === 1 && (
              <h4 className="weightDiv">{"Weightage : " + a.weightage}</h4>
            )}
          </div>
          <div className="actionDiv">
            {settingsDetails.kratypeweight === 1 && (
              <EditOutlined
                onClick={(event) => {
                  this.setCollDFL();
                  this.setEditColl(a, i);
                }}
              />
            )}
            <DeleteOutlined
              onClick={(event) => {
                this.setCollDFL();
                settingsDetails.kratypeweight === 1
                  ? this.removeColl(a, i)
                  : this.withColl(a, i);
              }}
            />
            {showcoll !== i ? (
              <DownOutlined onClick={() => this.setCollaps(i)} />
            ) : (
              <UpOutlined onClick={() => this.setCollaps(i)} />
            )}
          </div>
        </div>
      );
    } catch (error) {
      console.log(error);
    }
  };
  removeKpi = (a, tabInd, i) => {
    try {
      const { collapsArra } = this.state;
      collapsArra[i].kpiTableData.splice(tabInd, 1);
      collapsArra[i].toKpiWeight = collapsArra[i].toKpiWeight - a.kpiWeightage;
      this.setState({ collapsArra });
    } catch (error) {
      console.log(error);
    }
  };
  toggleModal = (a, i, val) => {
    try {
      const { addModal } = this.state;
      if (val === true) {
        let data = a.kpiTableData[i];
        let set = {
          id: data.id,
          kpi: data.kpi,
          kpiWeightage: data.kpiWeightage,
          kra: data.kra,
          kraID: data.kraID,
          index: i,
          edit: val,
        };
        this.setState({ editData: set });
      } else {
        this.setState({ editData: {} });
      }
      this.setState({ addModal: !addModal, kraId: a ? a.selectTypeID : 0 });
    } catch (error) {
      console.log(error);
    }
  };
  getSubmit = (a) => {
    try {
      const { collapsArra } = this.state;
      let daInd = collapsArra.findIndex(
        (x) => x.selectTypeID === a.selectedKra
      );
      collapsArra[daInd].toKpiWeight = a.toKpiWeight;
      if (a.index !== null) {
        collapsArra[daInd].kpiTableData[a.index] = {
          id: a.id,
          kpi: a.kpi,
          kpiWeightage: a.kpiWeightage,
          kra: a.kra,
          kraID: a.kraID,
        };
      } else {
        collapsArra[daInd].kpiTableData.push({
          id: a.id,
          kpi: a.kpi,
          kpiWeightage: a.kpiWeightage,
          kra: a.kra,
          kraID: a.kraID,
        });
      }
      this.setState({ collapsArra });
      this.toggleModal();
    } catch (error) {
      console.log(error);
    }
  };
  bodyUI = (x, ind) => {
    return (
      <div className="collBody">
        <hr />
        <div className="btnDiv" onClick={() => this.toggleModal(x)}>
          <PlusOutlined />
        </div>
        <div className="aprsTable">
          <table className="aprsDiv">
            <thead>
              <tr className="anime">
                <th>{"KRA"}</th>
                <th>{"KPI"}</th>
                <th>{"Weightage"}</th>
                <th>{""}</th>
              </tr>
            </thead>
            <tbody>
              {x?.kpiTableData?.map((a, i) => (
                <tr key={i} className="anime">
                  <td>{a.kra}</td>
                  <td>{a.kpi}</td>
                  <td>{a.kpiWeightage}</td>
                  <td className="actionTD">
                    <EditOutlined
                      onClick={() => this.toggleModal(x, i, true)}
                    />
                    <DeleteOutlined onClick={() => this.removeKpi(a, i, ind)} />
                  </td>
                </tr>
              ))}
            </tbody>
            {x?.kpiTableData.length > 0 && (
              <tfoot>
                <tr>
                  <td className="totalDiv">
                    {EmpConst.totalKpiWeight + x?.toKpiWeight}
                  </td>
                </tr>
              </tfoot>
            )}
          </table>
        </div>
      </div>
    );
  };
  collapseUI = () => {
    try {
      const { collapsArra, showcoll } = this.state;
      return collapsArra?.map((a, i) => (
        <div className="cusCollaps" key={i}>
          {this.headerUI(a, i)}
          {showcoll === i && this.bodyUI(a, i)}
        </div>
      ));
    } catch (error) {
      console.log(error);
    }
  };
  submitAllData = async () => {
    try {
      const { collapsArra, totalWeightage } = this.state;
      this.setState({ submitDis: true });
      setTimeout(() => this.setState({ submitDis: false }), 4500);
      let chekra = false;
      let flag = false;
      if (totalWeightage !== 100) {
        chekra = true;
        message.error(EmpConst.yorktWHu);
      }
      if (!chekra) {
        let postArray = [];
        if (!flag) {
          collapsArra?.forEach((a, i) => {
            let data = {
              wid: a.wid,
              kratypeid: a.selectTypeID,
              kratypeweight: a.weightage,
              empid: a.empid,
              kpiweightages: [],
            };
            let total = 0;
            a.kpiTableData?.forEach((x, i) => {
              total = total + x.kpiWeightage;
              data?.kpiweightages.push({
                id: x.id,
                kraid: x.kraID,
                kpiname: x.kpi,
                kpiweight: x.kpiWeightage,
              });
            });
            if (total !== 100) {
              flag = true;
              message.error(EmpConst.kWeGreHu);
            }
            postArray.push(data);
          });
          if (!flag) {
            await this.props.saveWeightage(postArray);
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  render() {
    const {
      weightage,
      weightageError,
      totalWeightage,
      selectType,
      selectTypeError,
      btnDisable,
      indexID,
      submitDis,
      collapsArra,
      addModal,
      kraId,
      editData,
    } = this.state;
    const { settingsDetails } = this.props;
    return (
      <>
        <Row gutter={24} className="kpiHeadDiv">
          <Col xs={24} sm={8} md={8} lg={8} xl={8}>
            {selectType === "" && this.selectAddUI("", selectTypeError)}
            {selectType !== "" && this.selectAddUI(selectType)}
          </Col>
          {settingsDetails.kratypeweight === 1 && (
            <Col xs={8} sm={4} md={4} lg={3} xl={3}>
              <Input
                number
                value={weightage}
                className={weightageError ? "empty" : ""}
                handleChange={this.handleWeight}
              />
            </Col>
          )}
          <Col xs={24} sm={12} md={12} lg={13} xl={13}>
            <div className="addBtnDiv">
              <Button
                disabled={btnDisable}
                onClick={
                  settingsDetails.kratypeweight === 1
                    ? this.handleAdd
                    : this.withAdd
                }
              >
                {indexID === null ? ButtonConst.add : ButtonConst.edit}
              </Button>
              {indexID !== null && (
                <Button disabled={btnDisable} onClick={this.handleClear}>
                  {ButtonConst.clr}
                </Button>
              )}
            </div>
          </Col>
        </Row>
        {this.collapseUI()}
        <div className="bottomDiv">
          <div className="bottText">
            {totalWeightage > 0 && settingsDetails.kratypeweight === 1
              ? EmpConst.totalWeight + totalWeightage
              : ""}
          </div>
          <div className="bottomBtn">
            {collapsArra[0]?.kpiTableData.length > 0 && (
              <Button onClick={this.submitAllData} disabled={submitDis}>
                {ButtonConst.submit}
              </Button>
            )}
          </div>
        </div>
        {addModal && (
          <KpiModal
            onOk={this.toggleModal}
            onCancel={this.toggleModal}
            selectedKra={kraId}
            collapsArra={collapsArra}
            editData={editData}
            getSubmit={this.getSubmit}
          />
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  master: state.master.master,
  weightageData: state.employee.weightageData,
  settingsDetails: state.organization.settingsDetails,
});
const mapDispatchToProps = (dispatch) => ({
  getMaster: (id) => dispatch(getMaster(id)),
  saveWeightage: (payload) => dispatch(saveWeightage(payload)),
  getWeightage: (id) => dispatch(getWeightage(id)),
  getSettings: (id) => dispatch(getSettings(id)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(KraKpi));
