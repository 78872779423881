const EmpConst = {
  le:"List of Employees",
  emp:"Employee",
  activate:"Activate ",
  deactivate:"Deactivate ",
  mass:"Are you sure you want to "
  
}
const TData = [
  {
    sr: 1,
    empCode: "HR-EMP-00001",
    empName: "Hardik Patel",
    email: "Hardik@gmail.com",
    mob: "8528529636",
  },
  {
    sr: 2,
    empCode: "HR-EMP-00002",
    empName: "Ananya  Pande",
    email: "Hardik@gmail.com",
    mob: "8528529636",
  },
  {
    sr: 3,
    empCode: "HR-EMP-00003",
    empName: "Divyesh Patel ",
    email: "Hardik@gmail.com",
    mob: "8528529636",
  },
  {
    sr: 4,
    empCode: "HR-EMP-00004",
    empName: "Jaynit Pandey",
    email: "Hardik@gmail.com",
    mob: "8528529636",
  },
  {
    sr: 5,
    empCode: "HR-EMP-00005",
    empName: "Parth Desai",
    email: "Hardik@gmail.com",
    mob: "8528529636",
  },
  {
    sr: 6,
    empCode: "HR-EMP-00006",
    empName: "Lareb Bhatt",
    email: "Hardik@gmail.com",
    mob: "8528529636",
  },
  {
    sr: 7,
    empCode: "HR-EMP-00006",
    empName: "Lareb Bhatt",
    email: "Hardik@gmail.com",
    mob: "8528529636",
  }, {
    sr: 8,
    empCode: "HR-EMP-00006",
    empName: "Lareb Bhatt",
    email: "Hardik@gmail.com",
    mob: "8528529636",
  },
]
export { TData,EmpConst };