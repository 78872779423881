import styled from "styled-components";
import { size } from "App/device";

const AddAppContainer = styled.div`
  .viewBtn {
    width: auto !important;
    height: auto !important;
    border-radius: 5px !important;
    font-size: 12px !important;
  }
  .tableView {
    overflow-x: auto;
    table {
      border-collapse: collapse;
      border-spacing: 0;
      width: 100%;
      border: 1px solid #ddd;
      margin-top: 1em;
      @media ${size["tablet-max"]} {
        min-width: 52em;
      }
      .scoreDiv {
        width: 14em;
      }
      .ant-upload {
        font-size: 20px;
        cursor: pointer;
      }
      .optionui .txtWrap {
        margin-right: 1em;
      }
      th,
      td {
        text-align: left;
        padding: 16px;
      }
      thead {
        background-color: #f2f2f2;
      }
      tbody tr:nth-child(even) {
        background-color: #f2f2f2;
      }
      tfoot {
        font-weight: bold;
      }
    }
  }
`;
export { AddAppContainer };
