import React, { Component } from "react";

import { TabsConst } from "./constant";

export class TabsUI extends Component {
  render() {
    const { countHandle, count ,tabs} = this.props;
    let tab = tabs.concat(TabsConst);
    return (
      <div className="tabsDiv">
        {tab?.map((a, i) => {
          return (
            <div
              key={i}
              className={`tabComp ${i === count ? "selectedTab" : ""}`}
              onClick={() => countHandle(a,i)}
            >
              {a.mtname}
            </div>
          );
        })}
      </div>
    );
  }
}
