export const SAVE_ORGANIZATION_INITIATED = "SAVE_ORGANIZATION_INITIATED";
export const SAVE_ORGANIZATION_SUCCESS = "SAVE_ORGANIZATION_SUCCESS";
export const SAVE_ORGANIZATION_ERROR = "SAVE_ORGANIZATION_ERROR";

export const GET_ORGANIZATION_LIST_INITIATED =
  "GET_ORGANIZATION_LIST_INITIATED";
export const GET_ORGANIZATION_LIST_SUCCESS = "GET_ORGANIZATION_LIST_SUCCESS";
export const GET_ORGANIZATION_LIST_ERROR = "GET_ORGANIZATION_LIST_ERROR";

export const GET_ORGANIZATION_BY_ID_INITIATED =
  "GET_ORGANIZATION_BY_ID_INITIATED";
export const GET_ORGANIZATION_BY_ID_SUCCESS = "GET_ORGANIZATION_BY_ID_SUCCESS";
export const GET_ORGANIZATION_BY_ID_ERROR = "GET_ORGANIZATION_BY_ID_ERROR";

export const DELETE_ORGANIZATION_INITIATED = "DELETE_ORGANIZATION_INITIATED";
export const DELETE_ORGANIZATION_SUCCESS = "DELETE_ORGANIZATION_SUCCESS";
export const DELETE_ORGANIZATION_ERROR = "DELETE_ORGANIZATION_ERROR";

export const GET_USERLISt_BYORGID_INITIATED = "GET_USERLISt_BYORGID_INITIATED";
export const GET_USERLISt_BYORGID_SUCCESS = "GET_USERLISt_BYORGID_SUCCESS";
export const GET_USERLISt_BYORGID_ERROR = "GET_USERLISt_BYORGID_ERROR";

export const SAVE_SETTINGS_INITIATED = "SAVE_SETTINGS_INITIATED";
export const SAVE_SETTINGS_SUCCESS = "SAVE_SETTINGS_SUCCESS";
export const SAVE_SETTINGS_ERROR = "SAVE_SETTINGS_ERROR";

export const GET_SETTINGS_INITIATED = "GET_SETTINGS_INITIATED";
export const GET_SETTINGS_SUCCESS = "GET_SETTINGS_SUCCESS";
export const GET_SETTINGS_ERROR = "GET_SETTINGS_ERROR";

export const GET_YEAR_INITIATED = "GET_YEAR_INITIATED";
export const GET_YEAR_SUCCESS = "GET_YEAR_SUCCESS";
export const GET_YEAR_ERROR = "GET_YEAR_ERROR";

export const SAVE_PERIOD_INITIATED = "SAVE_PERIOD_INITIATED";
export const SAVE_PERIOD_SUCCESS = "SAVE_PERIOD_SUCCESS";
export const SAVE_PERIOD_ERROR = "SAVE_PERIOD_ERROR";

export const GET_PERIOD_INITIATED = "GET_PERIOD_INITIATED";
export const GET_PERIOD_SUCCESS = "GET_PERIOD_SUCCESS";
export const GET_PERIOD_ERROR = "GET_PERIOD_ERROR";

export const GET_PERIOD_BYID_INITIATED = "GET_PERIOD_BYID_INITIATED";
export const GET_PERIOD_BYID_SUCCESS = "GET_PERIOD_BYID_SUCCESS";
export const GET_PERIOD_BYID_ERROR = "GET_PERIOD_BYID_ERROR";

export const GET_YEAR_REVIEW_INITIATED = "GET_YEAR_REVIEW_INITIATED";
export const GET_YEAR_REVIEW_SUCCESS = "GET_YEAR_REVIEW_SUCCESS";
export const GET_YEAR_REVIEW_ERROR = "GET_YEAR_REVIEW_ERROR";

export const DELETE_PERIOD_INITIATED = "DELETE_PERIOD_INITIATED";
export const DELETE_PERIOD_SUCCESS = "DELETE_PERIOD_SUCCESS";
export const DELETE_PERIOD_ERROR = "DELETE_PERIOD_ERROR";