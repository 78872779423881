import styled from "styled-components";

const ReportStyle = styled.div`
.secDiv{
    padding: 0 !important;
    .headDiv{
        .topBtn{
            margin-left:auto;
          }
    }
    .ant-row{
        align-items: center;
    }
    .repoBTN{
        display:flex;
        button:first-child {
            margin-right:1em;
          }
          .downBtn{
            min-width: 50px;
            font-size: 25px;
          }
    }
    .summHead {
        background: #ededed;
        color: #727171;
        padding: 0.5em 0;
        display: flex;
        align-items: center;
        justify-content: center;
        align-item: center;
        margin-top:1em;
      }
}
`;
export { ReportStyle };
