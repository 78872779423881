import React, { Component } from "react";
import { Modal, Spin } from "antd";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  PlusOutlined,
  QuestionCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";

import AddModal from "./AddModal";
import Period from "./Period";
import { TabsUI } from "./TabsUI";
import { StyleContainer } from "./style";
import { RemoveConst } from "App/AppConstant";
import { getOrgId } from "modules/helper";
import { MasterConstant } from "./constant";
import { Table, Input } from "components/Form";
import {
  getMasterType,
  getMaster,
  getKRA,
  deleteMaster,
  deleteKRA,
} from "redux/master/action";
import { getPeriod, getPeriodById ,deletePeriod } from "redux/organization/action";

const { confirm } = Modal;
var orgID = getOrgId();
class Master extends Component {
  constructor(props) {
    super(props);
    this.state = {
      preEdit: false,
      preView: false,
      perCount: 0,
      visible: false,
      count: 0,
      title: "Designation",
      searchVal: "",
      masterID: 1,
      editData: {},
    };
  }
  async componentDidMount() {
    try {
      const { masterID } = this.state;
      orgID = getOrgId();
      await this.props.getMasterType();
      await this.props.getMaster(masterID+"/"+orgID);
    } catch (error) {
      console.log(error);
    }
  }
  removeCall = (a) => {
    try {
      confirm({
        title: RemoveConst.header + MasterConstant.row,
        icon: <QuestionCircleOutlined />,
        content:
          RemoveConst.deleteMessage +
          MasterConstant.row.toLowerCase() +
          RemoveConst.que,
        okText: RemoveConst.yes,
        okType: "danger",
        cancelText: RemoveConst.no,
        onOk: () => {
          this.remove(a);
        },
      });
    } catch (error) {
      console.log(error);
    }
  };
  remove = async (id) => {
    try {
      const { masterID, title } = this.state;
      if (title !== "KRA" && title !== "Period") {
        await this.props.deleteMaster(id.mid);
        await this.props.getMaster(masterID+"/"+orgID);
      } else if (title === "KRA") {
        await this.props.deleteKRA(id.kraid);
        await this.props.getKRA(orgID);
      }else if(title === "Period"){
        await this.props.deletePeriod(id.periodid);
        await this.props.getPeriod(orgID)
      }
    } catch (error) {
      console.log(error);
    }
  };
  countHandle = async (a, i) => {
    await this.props.getMaster(i === 6 ? 6+"/"+orgID : a.mtid +"/"+orgID);
    i === 6 && (await this.props.getKRA(orgID));
    i === 7 && (await this.props.getPeriod(orgID));
    this.setState({
      count: i,
      title: a.mtname,
      masterID: a.mtid,
      perCount: 0,
      searchVal: "",
    });
  };
  toggleModal = async (val) => {
    try {
      const { visible } = this.state;
      this.setState({ visible: !visible, editData: val ? val : null });
    } catch (error) {
      console.log(error);
    }
  };
  viewCall = async (val, name) => {
    try {
      await this.props.getPeriodById(val.periodid);
      this.setPrCount(1, name);
    } catch (error) {
      console.log(error);
    }
  };
  setPrCount = (val, name) => {
    try {
      const { periodDetails } = this.props;
      this.setState({
        perCount: val,
        preEdit: name === "edit" ? true : false,
        preView: name === "view" ? true : false,
        editData: name ? periodDetails : null,
        searchVal: "",
      });
    } catch (error) {
      console.log(error);
    }
  };
  searchChange = (val) => this.setState({ searchVal: val.target.value });
  tableUI = () => {
    const { title, count, searchVal } = this.state;
    try {
      return (
        <div className="tableDiv anime" key="tbl">
          <div className="headDiv">
            <h2>{title}</h2>
            <div className="actDiv">
              <div className="searchDiv">
                <Input
                  value={searchVal}
                  suffix={<SearchOutlined />}
                  onChange={this.searchChange}
                />
              </div>
              <div
                className="btnDiv"
                onClick={() =>
                  count !== 7 ? this.toggleModal() : this.setPrCount(1)
                }
              >
                <PlusOutlined />
              </div>
            </div>
          </div>
          <Table
            data={
              count === 6
                ? this.props.kra
                : count === 7
                ? this.props.periodList
                : this.props.master
            }
            type={"master"}
            masterNo={count}
            showAction
            searchVal={searchVal}
            remove={this.removeCall}
            rowEdit={(val) => {
              count === 7 ? this.viewCall(val, "edit") : this.toggleModal(val);
            }}
            viewCall={(val) => this.viewCall(val, "view")}
          />
        </div>
      );
    } catch (error) {
      console.log(error);
    }
  };
  render() {
    const { loading } = this.props;
    const {
      count,
      title,
      visible,
      masterID,
      editData,
      perCount,
      preEdit,
      preView,
    } = this.state;
    return (
      <Spin spinning={loading} size="large">
        <StyleContainer>
          <TabsUI
            count={count}
            countHandle={this.countHandle}
            tabs={this.props.masters}
          />
          {perCount === 0 && this.tableUI()}
          {perCount === 1 && (
            <Period
              setPrCount={this.setPrCount}
              preEdit={preEdit}
              preView={preView}
              editData={editData}
            />
          )}
          {visible && (
            <AddModal
              onOk={this.toggleModal}
              onCancel={this.toggleModal}
              title={title}
              masterID={masterID}
              selectData={this.props.master}
              editData={editData}
              orgID={orgID}
            />
          )}
        </StyleContainer>
      </Spin>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.master.loading,
  masters: state.master.masters,
  master: state.master.master,
  kra: state.master.kra,
  periodList: state.organization.periodList,
  periodDetails: state.organization.periodDetails,
});
const mapDispatchToProps = (dispatch) => ({
  getMasterType: () => dispatch(getMasterType()),
  getMaster: (id) => dispatch(getMaster(id)),
  getKRA: (id) => dispatch(getKRA(id)),
  deleteMaster: (id) => dispatch(deleteMaster(id)),
  deleteKRA: (id) => dispatch(deleteKRA(id)),
  getPeriod: (id) => dispatch(getPeriod(id)),
  getPeriodById: (id) => dispatch(getPeriodById(id)),
  deletePeriod: (id) => dispatch(deletePeriod(id)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Master));
