const EmpConst = {
  ae: "Add New Employee",
  editAe: "Edit Employee",
  aea:"Add Employee Appraiser",
  eea:"Edit Employee Appraiser ",
  aekk:"Add Employee KRA & KPI",
  eekk:"Edit Employee KRA & KPI",
  ec: "Employee code*",
  empCode: "Employee Code",
  fname: "First Name*",
  lname: "Last Name*",
  mname: "Middle Name",
  mob: "Mobile Number*",
  email: "Email*",
  gender: "Gender*",
  role:"Role*",
  depart: "Department*",
  desi: "Designation*",
  report: "Report To",
  location: "Location",
  zone: "Zone",
  vertical: "Vertical",
  pwd: "Password*",
  selfapp: "Self Appraiser",
  admin: "Admin",
  hie: "Hierarchy",
  weight: "Weightage",
  kra: "KRA",
  kpi: "KPI",
  name:"Name",
  total:"Total : ",
  totalWeight:"Total Weightage : ",
  totalKpiWeight:"Total KPI Weightage : ",
  weAppShHun:"The Weightage of appraiser should be 100",
  yorktWHu:"Your KRA Type Weightage Must Be 100",
  kWeGreHu:"KPI Weightage should not be greater than 100%",
  // :""
};

const kraDrop = [
  {id:0,value:"KRA Type 1"},
  {id:1,value:"KRA Type 2"},
  {id:2,value:"KRA Type 3"},
  {id:3,value:"KRA Type 4"},
  {id:4,value:"KRA Type 5"},
]
const gender = [{ id: 1, value: "Male" }, { id: 2, value: "Female" }, { id: 3, value: "Other" }];
const department = [{ id: 1, value: "IT" }, { id: 2, value: "Marketing" }, { id: 3, value: "Account" }];
const designation = [{ id: 1, value: "Software engineer" }, { id: 1, value: "UI/UX developer" }, { id: 3, value: "Other" }];
const employee = [{ id: 1, value: "Hetvi" }, { id: 2, value: "Nency" }, { id: 3, value: "Jency" }];
const location = [{ id: 1, value: "Rajkot" }, { id: 2, value: "Surat" }, { id: 3, value: "Baroda" }];
const zone = [{ id: 1, value: "east" }, { id: 2, value: "west" }, { id: 3, value: "north" }, { id: 4, value: "south" }];
const vertical = [{ id: 1, value: "Vertical 1" }, { id: 2, value: "Vertical 2" }, { id: 3, value: "Vertical 3" }, { id: 4, value: "Vertical 4" }];
const role =[{id:2,value:"Admin"},{id:3,value:"HR"},{id:4,value:"Employee"}]
const KRA = ["KRA 1.1", "KRA 1.2", "KRA 1.3"];
const TData = [
  {
    sr: 1,
    kra: "Expansion",
    kpi: "",
    weight: "20",
    score: "4",
  },
  {
    sr: 2,
    kra: "Profitability",
    kpi: "",
    weight: "20",
    score: "5",
  },
  {
    sr: 3,
    kra: "Team Retention till GM Level + Team Engagement",
    kpi: "",
    weight: "30",
    score: "2",
  },
  {
    sr: 4,
    kra: "Safety & Security",
    kpi: "",
    weight: "10",
    score: "2",
  },
  {
    sr: 5,
    kra: "Market Based Innovation",
    kpi: "",
    weight: "20",
    score: "4",
  },
];

const KRAData = [
  {
    sr: 1,
    kra: "kra 1.1",
    kpi: "kpi 1",
    weights: "30%",
  },
  {
    sr: 2,
    kra: "kra 1.2",
    kpi: "kpi 2",
    weights: "20%",
  },
  {
    sr: 3,
    kra: "kra 1.3",
    kpi: "kpi 3",
    weights: "50%",
  },
  {
    sr: "",
    kra: "",
    kpi: "",
    weights: "Total 100%",
  },
];
export {
  EmpConst,
  kraDrop,
  gender,
  department,
  designation,
  employee,
  location,
  TData,
  KRA,
  KRAData,
  zone,
  vertical,
  role,
};
