import styled from "styled-components";
import { size } from "App/device";

const RepViewStyle = styled.div`
  .secDiv {
    padding: 0 !important;
    .bottomDiv {
      display: flex;
      padding: 16px;
      font-size: 16px;
      font-weight: bold;
      background-color: #f2f2f2;
      p {
        margin-bottom: 0;
        margin-left: auto;
        cursor: pointer;
      }
    }
    .pDiv {
      .flexDiv {
        display: flex;
        align-items: center;
        h4 {
          font-weight: bold;
        }
        .kraText {
          margin-right: 1em;
        }
      }
      .ant-col {
        display: flex;
        padding: 5px;
        align-items: center;
        .title {
          margin-right: 5px;
          font-size: 15px;
        }
        .valueDiv {
          font-weight: bold;
        }
      }
      .summHead {
        background: #ededed;
        color: #727171;
        padding: 0.5em 0;
        display: flex;
        align-items: center;
        justify-content: center;
        align-item: center;
        margin-top: 1em;
      }
      .tableDiv {
        overflow-x: auto;
        table {
          border-collapse: collapse;
          border-spacing: 0;
          width: 100%;
          border: 1px solid #ddd;
          margin-top: 1em;
          margin-bottom: 1em;
          @media ${size["tablet-max"]} {
            min-width: 52em;
          }
          th,
          td {
            text-align: left;
            padding: 16px;
          }
          thead {
            background-color: #f2f2f2;
          }
          tbody tr:nth-child(even) {
            background-color: #fafafa;
          }
        }
      }
    }
    .ant-collapse {
      h4 {
        margin-bottom: 0;
      }
      .textDiv {
        display: flex;
        align-items: center;
        width: 100%;
        .weightDiv {
          margin-left: 1em;
        }
        @media ${size["mobile-md-max"]} {
          display: block;
          .weightDiv {
            margin-left: 0;
          }
        }
      }
      .ant-collapse-header {
        .colHeadDiv {
        }
      }
      .ant-collapse-content {
        .collTabDiv {
          overflow-x: auto;
          table {
            border-collapse: collapse;
            border-spacing: 0;
            width: 100%;
            border: 1px solid #ddd;
            margin-top: 1em;
            margin-bottom: 1em;
            @media ${size["tablet-max"]} {
              min-width: 52em;
            }
            th,
            td {
              text-align: left;
              padding: 16px;
            }
            thead {
              background-color: #f2f2f2;
            }
            tbody tr:nth-child(even) {
              background-color: #fafafa;
            }
            .sizDiv {
              width: 10em;
            }
            .anticon-download {
              font-size: 17px;
              cursor: pointer;
            }
            tfoot tr {
              font-weight: bold;
            }
          }
        }
        .remarkDiv {
          min-height: 7em;
          box-shadow: 0px 0px 7px rgb(0 0 0 / 15%);
          border-radius: 10px;
          margin-top: 1em;
          padding: 0.5em 1em;
        }
      }
    }
  }
`;
export { RepViewStyle };
