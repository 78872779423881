const JrAppConst = {
  viewApp: " Appraiser",
  type: "KRA TYPE 1",
  remark: "Remarks",
  kra: "KRA",
  kpi: "KPI",
  score: "Score",
  doc: "Document",
};
const JuniorData = [
  {
    name: "Prekhsa",
    multipalKra: [
      {
        kraType: "Development BackEnd",
        tableData: [
          {
            kra: "KRA 1",
            kpi: "KPI 1",
            score: 4,
            doc: "",
          },
          {
            kra: "KRA 2",
            kpi: "KPI 2",
            score: 5,
            doc: "",
          },
          {
            kra: "KRA 3",
            kpi: "KPI 3",
            score: 3,
            doc: "",
          },
        ],
      },
      {
        kraType: "Development FrontEnd",
        tableData: [
          {
            kra: "KRA 1",
            kpi: "KPI 1",
            score: 4,
            doc: "",
          },
          {
            kra: "KRA 2",
            kpi: "KPI 2",
            score: 5,
            doc: "",
          },
        ],
      },
    ],
  },
  {
    name: "Rishabh",
    multipalKra: [
      {
        kraType: "Development BackEnd",
        tableData: [
          {
            kra: "KRA 1",
            kpi: "KPI 1",
            score: 4,
            doc: "",
          },
          {
            kra: "KRA 2",
            kpi: "KPI 2",
            score: 5,
            doc: "",
          },
          {
            kra: "KRA 3",
            kpi: "KPI 3",
            score: 3,
            doc: "",
          },
        ],
      },
      {
        kraType: "Development FrontEnd",
        tableData: [
          {
            kra: "KRA 1",
            kpi: "KPI 1",
            score: 4,
            doc: "",
          },
          {
            kra: "KRA 2",
            kpi: "KPI 2",
            score: 5,
            doc: "",
          },
        ],
      },
    ],
  },
];

export { JrAppConst, JuniorData };
