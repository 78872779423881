import styled from "styled-components";
import { size } from "App/device";
const StyleContainer = styled.div`
  .secDiv {
    padding: 0 !important;
    .pDiv {
      .ant-col {
        display: flex;
        padding: 5px;
        align-items: center;
        .title {
          margin-right: 5px;
          font-size: 15px;
        }
        .valueDiv {
          font-weight: bold;
        }
      }
      .flexDiv {
        display: flex;
        align-items: center;
        .kraText {
          margin-right: 1em;
        }
      }
      .tableDiv {
        overflow-x: auto;
        table {
          border-collapse: collapse;
          border-spacing: 0;
          width: 100%;
          border: 1px solid #ddd;
          margin-top: 1em;
          margin-bottom: 1em;
          @media ${size["tablet-max"]} {
            min-width: 52em;
          }
          th,
          td {
            text-align: left;
            padding: 16px;
          }
          thead {
            background-color: #f2f2f2;
          }
          tbody tr:nth-child(even) {
            background-color: #fafafa;
          }
        }
      }
    }
  }
`;
export { StyleContainer };
