import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";

import { StyleContainer } from "./style";
import { EmpViewConst, Gender  , Role} from "./constant";
import { getOrgId } from "modules/helper";
import { getMaster } from "redux/master/action";
import {
  getEmployeeByID,
  getAppraiser,
  getWeightage,
} from "redux/employee/action";
import { getUserListByOrgId, getSettings } from "redux/organization/action";
import { Row, Col } from "components/Form";
var orgID = getOrgId();

class EmployeeView extends Component {
  async componentDidMount() {
    try {
      const { match } = this.props;
      orgID =  getOrgId();
      await this.props.getSettings(orgID);
      await this.props.getMaster(0+"/"+orgID);
      await this.props.getUserListByOrgId(orgID + "/" + 0);
      await this.props.getEmployeeByID(parseInt(window.atob(match.params.id)));
      await this.props.getAppraiser(parseInt(window.atob(match.params.id)));
      await this.props.getWeightage(parseInt(window.atob(match.params.id)));
    } catch (error) {
      console.log(error);
    }
  }
  trUI = (a, b) => {
    try {
      return (
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <div className="title">{a}</div>
          <div className="valueDiv">{b}</div>
        </Col>
      );
    } catch (error) {
      console.log(error);
    }
  };
  render() {
    const {
      employeeDetails,
      master,
      userList,
      appraiserDetail,
      weightageData,
      settingsDetails,
    } = this.props;
    let dptdata = master.find((x) => x.mid === employeeDetails.departmentid);
    let desdata = master.find((x) => x.mid === employeeDetails.designationid);
    let locdata = master.find((x) => x.mid === employeeDetails.locationid);
    let zdata = master.find((x) => x.mid === employeeDetails.zoneid);
    let vtcdata = master.find((x) => x.mid === employeeDetails.verticalid);
    let repdata = userList.find((x) => x.userid === employeeDetails.reportto);
    return (
      <StyleContainer>
        <div className="secDiv">
          <div className="headDiv">
            <h2>{EmpViewConst.empView}</h2>
            <div
              className="btnDiv"
              onClick={() => this.props.history.push("/employee")}
            >
              <LeftOutlined />
            </div>
          </div>
          <div className="pDiv">
            <h2>{EmpViewConst.basDet}</h2>
            <Row gutter={24}>
              {this.trUI(
                EmpViewConst.empCode + EmpViewConst.colon,
                employeeDetails.empcode
              )}
              {this.trUI(
                EmpViewConst.firstNM + EmpViewConst.colon,
                employeeDetails.firstname
              )}
              {this.trUI(
                EmpViewConst.midNM + EmpViewConst.colon,
                employeeDetails.middlename !== ""
                  ? employeeDetails.middlename
                  : "---"
              )}
              {this.trUI(
                EmpViewConst.lastNM + EmpViewConst.colon,
                employeeDetails.lastname !== ""
                  ? employeeDetails.lastname
                  : "---"
              )}
              {this.trUI(
                EmpViewConst.gender + EmpViewConst.colon,
                Gender[employeeDetails.gender]
              )}
              {this.trUI(
                EmpViewConst.dpt + EmpViewConst.colon,
                dptdata && dptdata.mname
              )}
              {this.trUI(
                EmpViewConst.desg + EmpViewConst.colon,
                desdata && desdata.mname
              )}
              {this.trUI(
                EmpViewConst.moNo + EmpViewConst.colon,
                employeeDetails.mobileno
              )}
              {this.trUI(
                EmpViewConst.email + EmpViewConst.colon,
                employeeDetails.email
              )}
              {this.trUI(
                EmpViewConst.role + EmpViewConst.colon,
                Role[employeeDetails.isadmin]
              )}
              {this.trUI(
                EmpViewConst.repoTo + EmpViewConst.colon,
                repdata ? repdata.fullname : "---"
              )}
              {this.trUI(
                EmpViewConst.loc + EmpViewConst.colon,
                locdata ? locdata.mname : "---"
              )}
              {this.trUI(
                EmpViewConst.zone + EmpViewConst.colon,
                zdata ? zdata.mname : "---"
              )}
              {this.trUI(
                EmpViewConst.vrt + EmpViewConst.colon,
                vtcdata ? vtcdata.mname : "---"
              )}
              {this.trUI(
                EmpViewConst.slApp + EmpViewConst.colon,
                employeeDetails.allowselfappraisal === 1 ? "yes" : "no"
              )}
              {/* {this.trUI(
                EmpViewConst.admin + EmpViewConst.colon,
                employeeDetails.isadmin === 1 ? "yes" : "no"
              )} */}
            </Row>
          </div>
          {appraiserDetail?.length > 0 && (
            <div className="pDiv">
              <h2>{EmpViewConst.appreser}</h2>
              <div className="tableDiv">
                <table>
                  <thead>
                    <tr>
                      <th>{EmpViewConst.empCode}</th>
                      <th>{EmpViewConst.name}</th>
                      <th>{EmpViewConst.hirarchy}</th>
                      {settingsDetails?.appraiserweight === 1 && (
                        <th>{EmpViewConst.wght}</th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {appraiserDetail.map((a, i) => (
                      <tr key={i}>
                        <td>{a.empcode}</td>
                        <td>{a.name}</td>
                        <td>{a.level}</td>
                        {settingsDetails?.appraiserweight === 1 && (
                          <td>{a.weightage}</td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
          {weightageData.length > 0 && (
            <div className="pDiv">
              <h2>{EmpViewConst.kraKpi}</h2>
              {weightageData?.map((x) => (
                <>
                  <div className="flexDiv">
                    <h4 className="kraText">{x.kratypename}</h4>
                    {settingsDetails.kratypeweight === 1 && (
                      <h4>{`Weightage : ${x.kratypeweight}`}</h4>
                    )}
                  </div>
                  <hr />
                  <div className="tableDiv">
                    <table>
                      <thead>
                        <tr>
                          <th>{EmpViewConst.kra}</th>
                          <th>{EmpViewConst.kpi}</th>
                          <th>{EmpViewConst.wght}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {x.kpiweightages?.map((a, i) => (
                          <tr key={i}>
                            <td>{a.kraname}</td>
                            <td>{a.kpiname}</td>
                            <td>{a.kpiweight}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </>
              ))}
            </div>
          )}
        </div>
      </StyleContainer>
    );
  }
}
const mapStateToProps = (state) => ({
  master: state.master.master,
  userList: state.organization.userList,
  employeeDetails: state.employee.employeeDetails,
  appraiserDetail: state.employee.appraiserDetail,
  weightageData: state.employee.weightageData,
  settingsDetails: state.organization.settingsDetails,
});
const mapDispatchToProps = (dispatch) => ({
  getMaster: (id) => dispatch(getMaster(id)),
  getUserListByOrgId: (id) => dispatch(getUserListByOrgId(id)),
  getEmployeeByID: (id) => dispatch(getEmployeeByID(id)),
  getAppraiser: (id) => dispatch(getAppraiser(id)),
  getWeightage: (id) => dispatch(getWeightage(id)),
  getSettings: (id) => dispatch(getSettings(id)),
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EmployeeView)
);
