import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { SettingContainer } from "./style.js";
import { InputNumber, DatePicker, message } from "antd";

import { getOrgId } from "modules/helper";
import { ButtonConst } from "App/AppConstant.js";
import { SettingConst, calender } from "./constant";
import { saveSettings, getSettings } from "redux/organization/action";
import { Label, Select, RoundSwitch, Button, Row, Col } from "components/Form";
import moment from "moment";
const { RangePicker } = DatePicker;
var orgID = getOrgId();
// const monthFormat = "DD-MM-YYYY";
const monthFormat = "YYYY-MM-DD";
class Setting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alredy: false,
      settingsid: 0,
      isOpen: false,
      defaultValue: [],
      isDisable: true,
      switchVal: [],
      cal: "",
      calID: 0,
      calError: false,
      obtPoint: 5,
    };
  }
  async componentDidMount() {
    try {
      orgID = getOrgId();
      await this.props.getSettings(orgID);
    } catch (error) {
      console.log(error);
    }
  }
  componentDidUpdate(prevProps) {
    try {
      const { settingsDetails } = this.props;
      if (settingsDetails !== prevProps.settingsDetails) {
        settingsDetails.appraiserweight === 1 && this.checkClick(1);
        settingsDetails.kratypeweight === 1 && this.checkClick(2);
        settingsDetails.viewjuniorreview === 1 && this.checkClick(3);
        settingsDetails.allowafterjuniorreview === 1 && this.checkClick(4);

        if (settingsDetails.settingsid) {
          this.setState({
            alredy: true,
            settingsid: settingsDetails.settingsid,
            defaultValue: [
              moment(settingsDetails.frommonth),
              moment(settingsDetails.tomonth),
            ],
            cal:
              settingsDetails.calendartype === 1
                ? "Calendar year"
                : "Financial year",
            calID: settingsDetails.calendartype,
            obtPoint: settingsDetails.points,
          });
        } else {
          this.setState({ alredy: false });
        }
      }
    } catch (error) {
      console.log(error);
    }
  }
  selectUI = (val, error) => {
    try {
      return (
        <Select
          data={calender}
          value={val}
          defaultValue={val}
          withID
          className={error ? "empty" : ""}
          onChange={(value, data) => {
            this.setState({
              cal: value,
              calID: data.id,
              calError: false,
              defaultValue: [],
            });
          }}
        />
      );
    } catch (error) {
      console.log(error);
    }
  };
  checkClick = (val) => {
    try {
      const { switchVal } = this.state;
      let inx = switchVal.findIndex((a) => a === val);
      if (inx > -1) switchVal.splice(inx, 1);
      else switchVal.push(val);
      this.setState({
        switchVal,
      });
    } catch (error) {
      console.log(error);
    }
  };
  er = (r) => (r ? "empty" : "");
  getDate = (start) => {
    var a = start.split("-");
    var myDate = new Date(a[1] + "/" + a[2] + "/" + a[0]);
    var plusSeven = new Date(myDate.setDate(myDate.getDate() + 364));
    var ps = plusSeven.toLocaleString().split(",")[0].split("/");
    var myd = `${ps[2]}-${ps[0].length === 1 ? "0" + ps[0] : ps[0]}-${
      ps[1].length === 1 ? "0" + ps[1] : ps[1]
    }`;
    return moment(myd);
  };
  handleSave = async () => {
    try {
      const { settingsid, defaultValue, calID, obtPoint, switchVal } =
        this.state;
      let flag = false;
      if (calID === 0) {
        flag = true;
        this.setState({ calError: true });
      } else if (defaultValue.length === 0) {
        flag = true;
        message.error("Plz Select Date");
      }
      if (!flag) {
        let postData = {
          settingsid: settingsid,
          calendartype: calID,
          frommonth: defaultValue[0].format("YYYY-MM-DD"),
          tomonth: defaultValue[1].format("YYYY-MM-DD"),
          appraiserweight: switchVal.includes(1) ? 1 : 0,
          kratypeweight: switchVal.includes(2) ? 1 : 0,
          viewjuniorreview: switchVal.includes(3) ? 1 : 0,
          allowafterjuniorreview: switchVal.includes(4) ? 1 : 0,
          points: obtPoint,
          orgid: orgID,
        };
        console.log(postData);
        await this.props.saveSettings(postData);
      }
    } catch (error) {
      console.log(error);
    }
  };
  render() {
    const { cal, obtPoint, calID, calError, alredy, switchVal } = this.state;
    return (
      <SettingContainer>
        <div className="headDiv">
          <h2>{SettingConst.set}</h2>
          <div className="button">
            <div className="btn anime">
              {!alredy && (
                <Button onClick={this.handleSave}>{ButtonConst.save}</Button>
              )}
            </div>
          </div>
        </div>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Label title={SettingConst.ac} className={this.er(calError)} />
            {cal === "" && this.selectUI("", calError)}
            {cal !== "" && this.selectUI(cal)}
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Label title={SettingConst.tof} />
            <RangePicker
              className="pick"
              format={monthFormat}
              value={this.state.defaultValue}
              defaultValue={this.state.defaultValue}
              disabled={[calID > 0 ? false : true, true]}
              onCalendarChange={(x, y, z) => {
                if (z.range === "start")
                  this.setState({
                    defaultValue: [moment(y[0]), this.getDate(y[0])],
                    isOpen: false,
                  });
              }}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Label title={SettingConst.weigtha} />
            <RoundSwitch
              className="switch"
              left={SettingConst.no}
              right={SettingConst.yea}
              checked={switchVal.includes(1)}
              handleChange={() => this.checkClick(1)}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Label title={SettingConst.weightkra} />
            <RoundSwitch
              className="switch"
              left={SettingConst.no}
              right={SettingConst.yes}
              checked={switchVal.includes(2)}
              handleChange={() => this.checkClick(2)}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Label title={SettingConst.senApp} />
            <RoundSwitch
              className="switch"
              left={SettingConst.no}
              right={SettingConst.yes}
              checked={switchVal.includes(3)}
              handleChange={() => this.checkClick(3)}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Label title={SettingConst.allReviewed} />
            <RoundSwitch
              className="switch"
              left={SettingConst.no}
              right={SettingConst.yes}
              checked={switchVal.includes(4)}
              handleChange={() => this.checkClick(4)}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Label title={SettingConst.point} />
            <InputNumber
              className="number"
              type="number"
              min={5}
              max={10}
              value={obtPoint}
              defaultValue={obtPoint}
              onChange={(val) => {
                this.setState({ obtPoint: val });
              }}
            />
          </Col>
        </Row>
      </SettingContainer>
    );
  }
}
const mapStateToProps = (state) => ({
  settingsDetails: state.organization.settingsDetails,
});
const mapDispatchToProps = (dispatch) => ({
  saveSettings: (payload) => dispatch(saveSettings(payload)),
  getSettings: (id) => dispatch(getSettings(id)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Setting)
);
