export const SAVE_EMPLOYEE_INITIATED = "SAVE_EMPLOYEE_INITIATED";
export const SAVE_EMPLOYEE_SUCCESS = "SAVE_EMPLOYEE_SUCCESS";
export const SAVE_EMPLOYEE_ERROR = "SAVE_EMPLOYEE_ERROR";

export const GET_EMPLOYEE_LIST_INITIATED = "GET_EMPLOYEE_LIST_INITIATED";
export const GET_EMPLOYEE_LIST_SUCCESS = "GET_EMPLOYEE_LIST_SUCCESS";
export const GET_EMPLOYEE_LIST_ERROR = "GET_EMPLOYEE_LIST_ERROR";

export const GET_EMPLOYEE_BYID_INITIATED = "GET_EMPLOYEE_BYID_INITIATED";
export const GET_EMPLOYEE_BYID_SUCCESS = "GET_EMPLOYEE_BYID_SUCCESS";
export const GET_EMPLOYEE_BYID_ERROR = "GET_EMPLOYEE_BYID_ERROR";

export const DELET_EMPLOYEE_INITIATED = "DELET_EMPLOYEE_INITIATED";
export const DELET_EMPLOYEE_SUCCESS = "DELET_EMPLOYEE_SUCCESS";
export const DELET_EMPLOYEE_ERROR = "DELET_EMPLOYEE_ERROR";

export const SAVE_APPRAISER_INITIATED = "SAVE_APPRAISER_INITIATED";
export const SAVE_APPRAISER_SUCCESS = "SAVE_APPRAISER_SUCCESS";
export const SAVE_APPRAISER_ERROR = "SAVE_APPRAISER_ERROR";

export const GET_APPRAISER_INITIATED = "GET_APPRAISER_INITIATED";
export const GET_APPRAISER_SUCCESS = "GET_APPRAISER_SUCCESS";
export const GET_APPRAISER_ERROR = "GET_APPRAISER_ERROR";

export const SAVE_WEIGHTAGE_INITIATED = "SAVE_WEIGHTAGE_INITIATED";
export const SAVE_WEIGHTAGE_SUCCESS = "SAVE_WEIGHTAGE_SUCCESS";
export const SAVE_WEIGHTAGE_ERROR = "SAVE_WEIGHTAGE_ERROR";

export const GET_WEIGHTAGE_INITIATED = "GET_WEIGHTAGE_INITIATED";
export const GET_WEIGHTAGE_SUCCESS = "GET_WEIGHTAGE_SUCCESS";
export const GET_WEIGHTAGE_ERROR = "GET_WEIGHTAGE_ERROR";

export const GET_EMPAPP_BYID_INITIATED = "GET_EMPAPP_BYID_INITIATED";
export const GET_EMPAPP_BYID_SUCCESS = "GET_EMPAPP_BYID_SUCCESS";
export const GET_EMPAPP_BYID_ERROR = "GET_EMPAPP_BYID_ERROR"; 

export const GET_KRABY_EMPID_INITIATED = "GET_KRABY_EMPID_INITIATED";
export const GET_KRABY_EMPID_SUCCESS = "GET_KRABY_EMPID_SUCCESS";
export const GET_KRABY_EMPID_ERROR = "GET_KRABY_EMPID_ERROR"; 

export const SAVE_APPRAISAL_INITIATED = "SAVE_APPRAISAL_INITIATED";
export const SAVE_APPRAISAL_SUCCESS = "SAVE_APPRAISAL_SUCCESS";
export const SAVE_APPRAISAL_ERROR = "SAVE_APPRAISAL_ERROR"; 

export const GET_SCORE_BYID_INITIATED = "GET_SCORE_BYID_INITIATED";
export const GET_SCORE_BYID_SUCCESS = "GET_SCORE_BYID_SUCCESS";
export const GET_SCORE_BYID_ERROR = "GET_SCORE_BYID_ERROR"; 

export const GET_REPORT_LIST_INITIATED = "GET_REPORT_LIST_INITIATED";
export const GET_REPORT_LIST_SUCCESS = "GET_REPORT_LIST_SUCCESS";
export const GET_REPORT_LIST_ERROR = "GET_REPORT_LIST_ERROR"; 

export const GET_VIEW_APPRAISAl_INITIATED = "GET_VIEW_APPRAISAl_INITIATED";
export const GET_VIEW_APPRAISAl_SUCCESS = "GET_VIEW_APPRAISAl_SUCCESS";
export const GET_VIEW_APPRAISAl_ERROR = "GET_VIEW_APPRAISAl_ERROR";

export const GET_OWN_APPRAISAL_INITIATED = "GET_OWN_APPRAISAL_INITIATED";
export const GET_OWN_APPRAISAL_SUCCESS = "GET_OWN_APPRAISAL_SUCCESS";
export const GET_OWN_APPRAISAL_ERROR = "GET_OWN_APPRAISAL_ERROR";

export const GET_SUMMARY_DETAILS_INITIATED = "GET_SUMMARY_DETAILS_INITIATED";
export const GET_SUMMARY_DETAILS_SUCCESS = "GET_SUMMARY_DETAILS_SUCCESS";
export const GET_SUMMARY_DETAILS_ERROR = "GET_SUMMARY_DETAILS_ERROR";