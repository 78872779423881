import React, { Component } from "react";
import { JrAppContainer } from "./style.js";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Empty } from "antd";
import { LeftOutlined, DownloadOutlined } from "@ant-design/icons";

import { JrAppConst } from "./constant";
import { getAuthUserID ,getOrgId} from "modules/helper";
import { getSettings } from "redux/organization/action";
import { getViewAppraisal } from "redux/employee/action";
import { Collapse } from "components/Form";
import { configVar, masterConst } from "modules/config";

var orgID = getOrgId();
var userID = getAuthUserID();

class Junior extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeKey: ["0"],
    };
  }
  async componentDidMount() {
    try {
      const { passData } = this.props;
      userID = getAuthUserID();
      orgID = getOrgId();
      await this.props.getSettings(orgID);
      await this.props.getViewAppraisal(
        `${passData.yearid}/${passData.reviewperiodid}/${passData.empid}/${userID}/${passData.viewappraisal}`
      );
    } catch (error) {
      console.log(error);
    }
  }
  docDownload = (doc) => {
    try {
      let txt = doc.replace("/", "%5C");
      let downURL = configVar.BASE_URL + masterConst.DOWNLOAD_DOC + txt;
      var win = window.open(downURL, "_blank");
      win.focus();
    } catch (error) {
      console.log(error);
    }
  };
  collapseKey = (key) => this.setState({ activeKey: key });
  collapseUI = () => {
    try {
      const { viewAppraisel , settingsDetails } = this.props;
      let arrayUI = [];
      viewAppraisel?.forEach((a, i) => {
        arrayUI.push({
          header: <h4>{`${a.fullname}`}</h4>,
          body: (
            <>
              {a.kTLists?.map((x) => (
                <>
                  <h4>{`${x.kratypename}`}</h4>
                  <hr />
                  <table>
                    <thead>
                      <tr>
                        <th>{JrAppConst.kra}</th>
                        <th>{JrAppConst.kpi}</th>
                        <th>{JrAppConst.remark}</th>
                        <th className="sizDiv">{`${JrAppConst.score} (0- ${settingsDetails.points})`}</th>
                        <th className="sizDiv">{JrAppConst.doc}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {x.kPIScores?.map((y) => (
                        <tr key={i}>
                          <td>{y.kraname}</td>
                          <td>{y.kpiname}</td>
                          <td>{y.remarks}</td>
                          <td>{y.score}</td>
                          <td>
                            {y.docupload !== "" && (
                              <DownloadOutlined
                                onClick={() => this.docDownload(y.docupload)}
                              />
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              ))}
              {a?.remarks && (
                <>
                  <h4>{JrAppConst.remark}</h4>
                  <hr />
                  <div className="remarkDiv">{a?.remarks}</div>
                </>
              )}
            </>
          ),
        });
      });
      if (arrayUI.length > 0) {
        return <Collapse data={arrayUI} collapseKey={this.collapseKey} defaultActiveKey={this.state.activeKey}/>;
      }else{return<Empty   description={"Review  Not Given"}/>;}
    } catch (error) {
      console.log(error);
    }
  };
  // remUI = () => {
  //   try {
  //     const { viewAppraisel } = this.props;
  //     return <div className="remarkDiv">{viewAppraisel[0].remarks}</div>;
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  render() {
    const { passData ,viewAppraisel } = this.props;
    return (
      <JrAppContainer>
        <div className="secDiv">
          <div className="headDiv">
            <h2>{passData.empName + JrAppConst.viewApp}</h2>
            <div className="btnDiv" onClick={() => this.props.setPage()}>
              <LeftOutlined />
            </div>
          </div>
          {this.collapseUI()}
          {/* <Label title={JrAppConst.remark} />
          {this.remUI()} */}
        </div>
      </JrAppContainer>
    );
  }
}
const mapStateToProps = (state) => ({
  loading: state.employee.loading,
  viewAppraisel: state.employee.viewAppraisel,
  settingsDetails: state.organization.settingsDetails,
});
const mapDispatchToProps = (dispatch) => ({
  getViewAppraisal: (id) => dispatch(getViewAppraisal(id)),
  getSettings: (id) => dispatch(getSettings(id)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Junior));
