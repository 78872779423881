import React, { Component } from "react";
import * as Yup from "yup";
import { Spin, Avatar } from "antd";
import { connect } from "react-redux";
import { Formik, Form } from "formik";
import { withRouter } from "react-router-dom";

import { StyleContainer } from "container/Login/style";
import { PageConst } from "./constant";
import { login } from "redux/login/actions";
import { ButtonConst } from "App/AppConstant";
import { getOTP, forgetPassword } from "redux/login/actions";
import {
  Label,
  Input,
  Button,
  Header,
  FormGroup,
  FormItem,
} from "components/Form";
import { logo } from "components/Images";

const ValidationSchema = Yup.object().shape({
  // mobile: Yup.string().trim().max(10).min(10).required(" "),
  email: Yup.string().trim().email().required(" "),
});
const ValidationSchema2 = Yup.object().shape({
  otp: Yup.string().trim().max(6).min(6).required(" "),
  newpwd: Yup.string().trim().required(" "),
  conPwd: Yup.string()
    .trim()
    .required(" ")
    .when("newpwd", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref("newpwd")], PageConst.RepatePWD),
    }),
});
// .max(45, FormValidation.inputMax)// .email(FormValidation.emailInvalid)// .min(6, FormValidation.passwordMin)
// .max(25, FormValidation.passwordMax)// .required(FormValidation.passwordRequired),

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = { flag: false, isblock: false, ema: "", otp: "" };
  }
  async componentDidUpdate(prevProps) {
    const { OTP } = this.props;
    if (prevProps.OTP !== OTP) this.setState({ flag: true });
  }
  handleSubmit = async (values, { setSubmitting }) => {
    try {
      const { flag } = this.state;
      this.setState({ isblock: true });
      setTimeout(() => {
        this.setState({ isblock: false });
      }, 4500);
      if (!flag) {
        await this.props.getOTP(values.email.trim());
        this.setState({ ema: values.email.trim() });
      } else {
        // console.log(this.state.ema);
        await this.props.forgetPassword(
          this.state.ema + "/" + values.otp + "/" + values.newpwd
        );
      }
      setSubmitting(false);
    } catch (error) {
      console.log(error, "handle error");
    }
  };
  removeMbl = () => this.setState({ flag: !this.state.flag });
  render() {
    const { loading } = this.props;
    const { isblock, flag, otp } = this.state;
    return (
      <Spin spinning={loading} size="large">
        <StyleContainer>
          <Header />
          <div className="form-div anime log-form">
            <Avatar src={logo} size={80} />
            <Label className="head anime" title={PageConst.cp} />
            <div className="loginDiv anime">
              {flag ? (
                <Formik
                  initialValues={{ otp: otp, newpwd: "", conPwd: "" }}
                  validationSchema={ValidationSchema2}
                  onSubmit={this.handleSubmit}
                  enableReinitialize
                >
                  {({
                    values,
                    errors,
                    touched,
                    onBlur,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                  }) => (
                    <Form onSubmit={handleSubmit} noValidate>
                      <FormItem>
                        <FormGroup>
                          <Label
                            title={PageConst.otp}
                            className={errors.otp && touched.otp ? "empty" : ""}
                          />
                          <Input
                            name="otp"
                            type="number"
                            value={values.otp}
                            onBlur={handleBlur}
                            handleChange={handleChange}
                            className={errors.otp && touched.otp ? "empty" : ""}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label
                            title={PageConst.newpwd}
                            className={
                              errors.newpwd && touched.newpwd ? "empty" : ""
                            }
                          />
                          <Input
                            password
                            max={25}
                            onBlur={handleBlur}
                            name="newpwd"
                            value={values.newpwd}
                            handleChange={handleChange}
                            className={
                              errors.newpwd && touched.newpwd ? "empty" : ""
                            }
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label
                            title={PageConst.conpwd}
                            className={
                              errors.conPwd && touched.conPwd ? "empty" : ""
                            }
                          />
                          <Input
                            password
                            onBlur={handleBlur}
                            name="conPwd"
                            value={values.conPwd}
                            handleChange={handleChange}
                            max={25}
                            tabIndex="3"
                            className={
                              errors.conPwd && touched.conPwd ? "empty" : ""
                            }
                          />
                          {errors.conPwd && touched.conPwd && (
                            <div className="form-error">{errors.conPwd}</div>
                          )}
                        </FormGroup>
                      </FormItem>
                      <div className="btnDiv">
                        <div className="btn">
                          <Button
                            disabled={isblock}
                            onClick={() => this.removeMbl()}
                          >
                            {ButtonConst.cancel}
                          </Button>
                          <Button type="submit" disabled={isblock}>
                            {ButtonConst.submit}
                          </Button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              ) : (
                <Formik
                  initialValues={{ email: "" }}
                  validationSchema={ValidationSchema}
                  onSubmit={this.handleSubmit}
                >
                  {({
                    values,
                    errors,
                    touched,
                    onBlur,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                  }) => (
                    <Form onSubmit={handleSubmit} noValidate>
                      <FormItem>
                        <FormGroup>
                          <Label
                            title={PageConst.email}
                            className={
                              errors.email && touched.email ? "empty" : ""
                            }
                          />
                          <Input
                            name="email"
                            // type="number"
                            onBlur={handleBlur}
                            value={values.email}
                            handleChange={handleChange}
                            className={
                              errors.email && touched.email ? "empty" : ""
                            }
                          />
                        </FormGroup>
                      </FormItem>
                      <div className="btnDiv">
                        <div className="btn">
                          <Button
                            disabled={isblock}
                            onClick={() => this.props.history.push("/login")}
                          >
                            {ButtonConst.cancel}
                          </Button>
                          <Button type="submit" disabled={isblock}>
                            {PageConst.getOtp}
                          </Button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              )}
            </div>
          </div>
        </StyleContainer>
      </Spin>
    );
  }
}

const mapStateToProps = (state) => ({
  OTP: state.login.OTP,
  loading: state.login.loading,
  isAuthenticated: state.login.isAuthenticated,
});
const mapDispatchToProps = (dispatch) => ({
  login: (payload) => dispatch(login(payload)),
  getOTP: (payload) => dispatch(getOTP(payload)),
  forgetPassword: (payload) => dispatch(forgetPassword(payload)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
