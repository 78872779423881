const reportConst = {
  repView: "Report View",
  kra: "KRA",
  kpi: "KPI",
  weight: "Weightage",
  finalIm: "FInal Impact",
  obtScore: "Obtained Score",
  maxObtScore: "Maximum Obtainable Score",
  finalSco: "FinalScore : ",
  colon: ": ",
  empCode: "Employee code ",
  revFor: "Review For ",
  empName: "Employee name ",
  repPers: "Reporting Person ",
  depa: "Department ",
  des: "Designation ",
  revBY: "Reviewed by ",
  sumReport: "Appraisal Summary",
  remark: "Remarks",
  viDe:"View Details",
  level:"Level : ",
 obtOutOf :"Obtained out of ",
 finalScor :"Final score",
 document :"Document",
 tot :"Total : ",
};

const viewTableData = [
  {
    level: "Level 1",
    name: "Sunil Gohel",
    Weightage: "20%",
    rema:"gfjs adfm dgfkmnd odf, sdhfolsdm,f dhsfikld,mfhnsdofsdf,sdfpsdfds.,f",
    kraTypeData: [
      {
        kratype: "KRA TYPE 1",
        kraTW: "50%",
        totW: "100%",
        totalfinal: "0.47",
        tabledata: [
          {
            kra: "KRA 1",
            kpi: "KPI 1",
            weght: "30%",
            obt: "4",
            fiSco: "0.12",
            Remarks: "jsda",
          },
          {
            kra: "KRA 2",
            kpi: "KPI 2",
            weght: "70%",
            obt: "5",
            fiSco: "0.35",
            Remarks: "jsda",
          },
        ],
      },
      {
        kratype: "KRA TYPE 2",
        kraTW: "50%",
        totW: "100%",
        totalfinal: "0.45",
        tabledata: [
          {
            kra: "KRA 1",
            kpi: "KPI 1",
            weght: "50%",
            obt: "5",
            fiSco: "0.25",
            Remarks: "jsda",
          },
          {
            kra: "KRA 2",
            kpi: "KPI 2",
            weght: "50%",
            obt: "4",
            fiSco: "0.20",
            Remarks: "jsda",
          },
        ],
      },
    ],
  },
  {
    level: "Level 2",
    name: "Abhishek Jain",
    Weightage: "80%",
    rema:"gfjs adfm dgfkmnd odf, sdhfolsdm,f dhsfikld,mfhnsdofsdf,sdfpsdfds.,f",
    kraTypeData: [
      {
        kratype: "KRA TYPE 1",
        kraTW: "50%",
        totW: "100%",
        totalfinal: "2.00",
        tabledata: [
          {
            kra: "KRA 1",
            kpi: "KPI 1",
            weght: "30%",
            obt: "5",
            fiSco: "0.60",
            Remarks: "jsda",
          },
          {
            kra: "KRA 2",
            kpi: "KPI 2",
            weght: "70%",
            obt: "5",
            fiSco: "1.40",
            Remarks: "jsda",
          },
        ],
      },
      {
        kratype: "KRA TYPE 2",
        kraTW: "50%",
        totW: "100%",
        totalfinal: "1",
        tabledata: [
          {
            kra: "KRA 1",
            kpi: "KPI 1",
            weght: "50%",
            obt: "3",
            fiSco: "0.6",
            Remarks: "jsda",
          },
          {
            kra: "KRA 2",
            kpi: "KPI 2",
            weght: "50%",
            obt: "2",
            fiSco: "0.4",
            Remarks: "jsda",
          },
        ],
      },
    ],
  },
];

export { reportConst ,viewTableData };
