
const selectData = [
  {
    id: 1,
    value: "2022",
  },
  {
    id: 2,
    value: "2023",
  },
];
const reportConst = {
  rep: "Report",
  year:"Year",
  period:"Period",
  search:"Search...",
  apply:"Apply",
  excel:"Excel",
  sumReport:"Summary Report",
};
const reportArray = [
  { key: "no", label: "No" },
  { key: "empcode", label: "Employee Code" },
  { key: "fullname", label: "Name" },
  { key: "department", label: "Department" },
  { key: "designation", label: "Designation" },
  { key: "finalscore", label: "Final Score" },
];
const empRepoArray=[
  { key: "no", label: "No" },
  { key: "reviewperiodname", label: "Review Preiod" },
  { key: "yearname", label: "Year" },
  { key: "score", label: "Obtained Score" },
];

export { selectData, reportConst ,reportArray ,empRepoArray};
