import React, { Component } from "react";
import { DatePicker } from "antd";
import moment from "moment";
import { StyledComponent } from "./style";
const dateFormat = "YYYY-MM-DD";

class Datepicker extends Component {
  handleChange = (date, dateString) => {
    this.props.handleChange(dateString);
  };
  render() {
    const { className, disabled, defaultValue, value, id } = this.props;
    let did = id ? "form-dropdown" + id : "form-dropdown";
    return (
      <StyledComponent id={did}>
        <DatePicker
          placeholder=""
          className={className}
          disabled={disabled}
          format={dateFormat}
          value={value && moment(value, dateFormat)}
          defaultValue={defaultValue && moment(defaultValue, dateFormat)}
          onChange={this.handleChange}
          getPopupContainer={() => document.getElementById(did)}
        />
      </StyledComponent>
    );
  }
}
Datepicker.defaultProps = {};
export default Datepicker;
