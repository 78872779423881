const EmAppConst = {
  head: "Employee Appraisal",
  year: "Year",
  period: "Period",
  search:"Search...",
};
const empData = [
  {
    sr: 1,
    empName: "Hetvi Kanani",
    dept: "IT",
    des: "React",
    sdate: "25/5/2022",
    edate: "25/5/2022",
  },
  {
    sr: 2,
    empName: "Hardik Patel",
    dept: "IT",
    des: "React",
    sdate: "25/5/2022",
    edate: "25/5/2022",
  },
  {
    sr: 3,
    empName: "Hardik Patel",
    dept: "IT",
    des: "React",
    sdate: "25/5/2022",
    edate: "25/5/2022",
  },
];
export { EmAppConst, empData };
