import React, { Component } from "react";
import { Modal, Spin } from "antd";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  PlusOutlined,
  QuestionCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";

import { EmpConst } from "./constant";
import { getOrgId } from "modules/helper";
import { StyleContainer } from "./style.js";
import { Table, Input } from "components/Form";
import { RemoveConst } from "App/AppConstant";
import { getEmployee, deleteEmployee } from "redux/employee/action";
const { confirm } = Modal;
var orgID = getOrgId();
class Employee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pagination: { current: 1, pageSize: 10, total: 0 },
      searchVal: "",
      paramet: {
        pageno: 1,
        pagesize: 10,
        sortcol: "u.userid desc",
        whereclause: "",
        orgid: 0,
      },
    };
  }
  async componentDidMount() {
    try {
      orgID = getOrgId();
      const { paramet } = this.state;
      paramet.orgid = parseInt(orgID);
      await this.props.getEmployee(paramet);
      localStorage.removeItem("employeeId");
    } catch (error) {
      console.log(error);
    }
  }
  removeCall = (id) => {
    try {
      confirm({
        title: `${id.isactive === 0 ? EmpConst.deactivate : EmpConst.activate}${
          EmpConst.emp
        }`,
        icon: <QuestionCircleOutlined />,
        content:
          EmpConst.mass +
          `${id.isactive === 0 ? EmpConst.deactivate : EmpConst.activate}` +
          EmpConst.emp.toLowerCase() +
          RemoveConst.que,
        okText: RemoveConst.yes,
        okType: "danger",
        cancelText: RemoveConst.no,
        onOk: () => {
          this.removeRow(id);
        },
      });
    } catch (error) {
      console.log(error);
    }
  };
  removeRow = async (id) => {
    try {
      const { paramet } = this.state;
      await this.props.deleteEmployee(
        `${id.empid}/${id.isactive === 0 ? 1 : 0}`
      );
      await this.props.getEmployee(paramet);
    } catch (error) {
      console.log(error);
    }
  };
  handleCustm = async (val) => {
    try {
      const { paramet } = this.state;
      paramet.pageno = val.current;
      paramet.pagesize = val.pageSize;
      await this.props.getEmployee(paramet);
      this.setState({ pagination: val, paramet });
    } catch (error) {
      console.log(error);
    }
  };
  searchChange = async (val) => {
    try {
      const { paramet, pagination } = this.state;
      paramet.whereclause = val.target.value;
      paramet.pageno = 1;
      pagination.current = 1;
      this.setState({ pagination, paramet, searchVal: val.target.value });
      await this.props.getEmployee(paramet);
    } catch (error) {
      console.log(error);
    }
  };
  render() {
    const { pagination, searchVal } = this.state;
    const { employeeList, loading, empTotalrec } = this.props;
    pagination.total = empTotalrec;
    return (
      <Spin spinning={loading} size="large">
        <StyleContainer>
          <div className="secDiv">
            <div className="headDiv">
              <h2>{EmpConst.le}</h2>
              <div className="actDiv">
                <div className="searchDiv">
                  <Input
                    value={searchVal}
                    suffix={<SearchOutlined />}
                    onChange={this.searchChange}
                  />
                </div>
                <div
                  className="btnDiv"
                  onClick={() => this.props.history.push("/employee/new")}
                >
                  <PlusOutlined />
                </div>
              </div>
            </div>
            <Table
              showAction
              type="emp"
              data={employeeList}
              remove={this.removeCall}
              custmPagi={empTotalrec > 10 && pagination}
              handleCustm={this.handleCustm}
            />
          </div>
        </StyleContainer>
      </Spin>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.employee.loading,
  employeeList: state.employee.employeeList,
  empTotalrec: state.employee.empTotalrec,
});
const mapDispatchToProps = (dispatch) => ({
  getEmployee: (payload) => dispatch(getEmployee(payload)),
  deleteEmployee: (id) => dispatch(deleteEmployee(id)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Employee)
);
