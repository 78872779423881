import React, { Component } from "react";
import * as Yup from "yup";
import { Spin } from "antd";
import { connect } from "react-redux";
import { Formik, Form } from "formik";
import { withRouter } from "react-router-dom";

import { StyleContainer } from "container/Login/style";
import { PageConst } from "./constant";
import { login, changePassword } from "redux/login/actions";
import { ButtonConst } from "App/AppConstant";
import { getAuthUserID } from "modules/helper";
import { Label, Input, Button, FormGroup, FormItem } from "components/Form";

var userID = getAuthUserID();
const ValidationSchema = Yup.object().shape({
  oldpwd: Yup.string().trim().required(" "),
  newpwd: Yup.string().trim().required(" "),
  conPwd: Yup.string()
    .trim()
    .required(" ")
    .when("newpwd", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref("newpwd")], PageConst.RepatePWD),
    }),
  // .max(45, FormValidation.inputMax)
  // .email(FormValidation.emailInvalid)
  // .min(6, FormValidation.passwordMin)
  // .max(25, FormValidation.passwordMax)
  // .required(FormValidation.passwordRequired),
});

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = { isblock: false };
  }
  // async componentDidUpdate(prevProps) { loading: false,
  //   const { loading } = this.props;if (prevProps.loading !== loading) {this.setState({ loading });
  //     setInterval(() => {this.state.loading && this.setState({ loading: false });}, 4000);}}

  async componentDidMount() {
    try {
      userID = getAuthUserID();
      
    } catch (error) {
      console.log(error);
    }
  }
  handleSubmit = async (values, { setSubmitting }) => {
    try {
      this.setState({ isblock: true });
      setTimeout(() => {
        this.setState({ isblock: false });
      }, 4500);
      let data =`${userID}/${values.oldpwd.trim()}/${values.newpwd.trim()} `;
      await this.props.changePassword(data);
      setSubmitting(false);
    } catch (error) {
      console.log(error, "handle error");
    }
  };
  render() {
    const { loading } = this.props;
    const { isblock } = this.state;
    return (
      <Spin spinning={loading} size="large">
        <StyleContainer className="change">
          <div className="form-div anime">
            <Label className="head anime" title={PageConst.cp} />
            <div className="loginDiv anime">
              <Formik
                initialValues={{ oldpwd: "", newpwd: "", conPwd: "" }}
                validationSchema={ValidationSchema}
                onSubmit={this.handleSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  onBlur,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <Form onSubmit={handleSubmit} noValidate>
                    <FormItem>
                      <FormGroup>
                        <Label
                          title={PageConst.oldpwd}
                          className={
                            errors.oldpwd && touched.oldpwd ? "empty" : ""
                          }
                        />
                        <Input
                          password
                          max={25}
                          onBlur={handleBlur}
                          name="oldpwd"
                          value={values.oldpwd.trim()}
                          handleChange={handleChange}
                          className={
                            errors.oldpwd && touched.oldpwd ? "empty" : ""
                          }
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label
                          title={PageConst.newpwd}
                          className={
                            errors.newpwd && touched.newpwd ? "empty" : ""
                          }
                        />
                        <Input
                          password
                          max={25}
                          onBlur={handleBlur}
                          name="newpwd"
                          value={values.newpwd.trim()}
                          handleChange={handleChange}
                          className={
                            errors.newpwd && touched.newpwd ? "empty" : ""
                          }
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label
                          title={PageConst.conpwd}
                          className={
                            errors.conPwd && touched.conPwd ? "empty" : ""
                          }
                        />
                        <Input
                          password
                          onBlur={handleBlur}
                          name="conPwd"
                          value={values.conPwd.trim()}
                          handleChange={handleChange}
                          max={25}
                          tabIndex="3"
                          className={
                            errors.conPwd && touched.conPwd ? "empty" : ""
                          }
                        />
                        {errors.conPwd && touched.conPwd && (
                          <div className="form-error">{errors.conPwd}</div>
                        )}
                      </FormGroup>
                      <div className="btnDiv">
                        <Button type="submit" disabled={isblock}>
                          {ButtonConst.submit}
                        </Button>
                      </div>
                    </FormItem>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </StyleContainer>
      </Spin>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.login.isAuthenticated,
  loading: state.login.loading,
  error: state.login.error,
  message: state.login.message,
});
const mapDispatchToProps = (dispatch) => ({
  login: (payload) => dispatch(login(payload)),
  changePassword: (payload) => dispatch(changePassword(payload)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ChangePassword)
);
