import React, { Component } from "react";
import { Spin } from "antd";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { Label, Input } from "components/Form";
import { ProfileStyle } from "./style.js";
import { Row, Col } from "components/Form";
import { getEmployeeByID } from "redux/employee/action";
import { getMaster } from "redux/master/action";
import { getUserListByOrgId } from "redux/organization/action";
import { ProConst, Gender } from "./constant";
import { getAuthUserID, getOrgId, getAuthRole } from "modules/helper";
var userId = getAuthUserID();
var orgID = getOrgId();
var role = getAuthRole();
class MyProfile extends Component {
  constructor() {
    super();
    this.state = { remember: false, check: [] };
  }
  async componentDidMount() {
    try {
      userId = getAuthUserID();
      orgID = getOrgId();
      role = getAuthRole();
      this.props.getEmployeeByID(userId);
      await this.props.getMaster(0 + "/" + orgID);
      await this.props.getUserListByOrgId(orgID + "/" + 0);
    } catch (error) {
      console.log(error);
    }
  }
  render() {
    const { employeeDetails, master, userList, loading } = this.props;
    let department = master.find((x) => x.mid === employeeDetails.departmentid);
    let designation = master.find(
      (x) => x.mid === employeeDetails.designationid
    );
    let zone = master.find((x) => x.mid === employeeDetails.zoneid);
    let location = master.find((x) => x.mid === employeeDetails.locationid);
    let vertical = master.find((x) => x.mid === employeeDetails.verticalid);
    let report = userList.find((x) => x.userid === employeeDetails.reportto);
    return (
      <Spin spinning={loading} size="large">
        <ProfileStyle>
          <div className="pDiv">
            <h2 className="header">{ProConst.pdetail}</h2>
            <Row gutter={24}>
              {role !== 1 && (
                <Col xs={24} sm={24} md={12} lg={12} xl={12} className="anime">
                  <Label title={ProConst.emp} />
                  <Input value={employeeDetails.empcode} />
                </Col>
              )}
              <Col xs={24} sm={24} md={12} lg={12} xl={12} className="anime">
                <Label title={ProConst.flname} />
                <Input value={employeeDetails.firstname} />
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} className="anime">
                <Label title={ProConst.mname} />
                <Input value={employeeDetails.middlename} />
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} className="anime">
                <Label title={ProConst.lname} />
                <Input value={employeeDetails.lastname} />
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} className="anime">
                <Label title={ProConst.gen} />
                <Input value={Gender[employeeDetails.gender]} />
              </Col>
              {role !== 1 && (
                <Col xs={24} sm={24} md={12} lg={12} xl={12} className="anime">
                  <Label title={ProConst.dep} />
                  <Input value={department && department.mname} />
                </Col>
              )}
              {role !== 1 && (
                <Col xs={24} sm={24} md={12} lg={12} xl={12} className="anime">
                  <Label title={ProConst.desi} />
                  <Input value={designation && designation.mname} />
                </Col>
              )}
              <Col xs={24} sm={24} md={12} lg={12} xl={12} className="anime">
                <Label title={ProConst.no} />
                <Input value={employeeDetails.mobileno} />
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} className="anime">
                <Label title={ProConst.email} />
                <Input value={employeeDetails.email} />
              </Col>
              {role !== 1 && (
                <Col xs={24} sm={24} md={12} lg={12} xl={12} className="anime">
                  <Label title={ProConst.report} />
                  <Input value={report && report.fullname} />
                </Col>
              )}
              <Col xs={24} sm={24} md={12} lg={12} xl={12} className="anime">
                <Label title={ProConst.location} />
                <Input value={location && location.mname} />
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} className="anime">
                <Label title={ProConst.zone} />
                <Input value={zone && zone.mname} />
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} className="anime">
                <Label title={ProConst.vertical} />
                <Input value={vertical && vertical.mname} />
              </Col>
            </Row>
          </div>
        </ProfileStyle>
      </Spin>
    );
  }
}
const mapStateToProps = (state) => ({
  loading: state.employee.loading,
  employeeDetails: state.employee.employeeDetails,
  master: state.master.master,
  userList: state.organization.userList,
});
const mapDispatchToProps = (dispatch) => ({
  getEmployeeByID: (id) => dispatch(getEmployeeByID(id)),
  getMaster: (id) => dispatch(getMaster(id)),
  getUserListByOrgId: (id) => dispatch(getUserListByOrgId(id)),
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MyProfile)
);
