const SettingConst = {
  ac: "Appraisal Calender",
  tof: "From To",
  weigtha: "Would you prefer a Weightage appraiser?",
  yes: "Yes",
  no: "No",
  weightkra: "Do you want Weightage KRA types ",
  point: "What is the maximum obtainable point for appraisal? ",
  set: "Setting",
  senApp: "Can senior appraiser view the junior review?",
  allReviewed: "To allow only after all the juniors have reviewed",
};

const calender = [
  { id: 1, value: "Calendar year" },
  { id: 2, value: "Financial year" },
];

export { SettingConst, calender };
