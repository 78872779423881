import React, { Component } from "react";
import { Table } from "antd";
import { withRouter } from "react-router-dom";
import {
  EyeOutlined,
  EditOutlined,
  DeleteOutlined,
  ScheduleOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";

import TableStyle from "./style";
import { PageConst, masterConst } from "./constant";
import { Popover } from "components/Form";
import { getAuthRole } from "modules/helper";
const { Column } = Table;
var role = getAuthRole();
class TableUI extends Component {
  constructor(props) {
    super(props);
    this.state = { pagination: { current: 1, pageSize: 10 } };
  }
  async componentDidMount() {
    try {
      role = getAuthRole();
    } catch (error) {
      console.log(error);
    }
  }
  handleTable = (pagination) => this.setState({ pagination });
  viewOnClick = (a, type) => {
    try {
      type === "emp" &&
        this.props.history.push("/employee/view/" + window.btoa(a.empid));
      type === "master" && this.props.viewCall(a);
    } catch (error) {
      console.log(error);
    }
  };
  editOnClick = (a, type) => {
    try {
      if (type === "emp")
        this.props.history.push("/employee/edit/" + window.btoa(a.empid));
      else {
        this.props.rowEdit(a);
      }
    } catch (error) {
      console.log(error);
    }
  };
  removeOnClick = (a, type) => {
    try {
      this.props.remove(type === "emp" ? a : a);
    } catch (error) {
      console.log(error);
    }
  };
  actionUi = (a, type) => {
    try {
      const { masterNo } = this.props;
      return (
        <div className="actionUI" key="master">
          {(type === "emp" || masterNo === 7) && (
            <div
              className="actionDiv"
              onClick={() => this.viewOnClick(a, type)}
            >
              <EyeOutlined />
            </div>
          )}
          <div className="actionDiv" onClick={() => this.editOnClick(a, type)}>
            <EditOutlined />
          </div>
          {type !== "emp" ? (
            <div
              className="actionDiv"
              onClick={() => this.removeOnClick(a, type)}
            >
              <DeleteOutlined />
            </div>
          ) : (
            <Popover
              content={a.isactive === 0 ? "Deactivate" : "Activate"}
              trigger="hover"
            >
              <div
                className="actionDiv"     
                onClick={() => this.removeOnClick(a, type)}           
              >
             {a.isactive===0?<CloseCircleOutlined />:<CheckCircleOutlined />}
              </div>
            </Popover>
          )}
        </div>
      );
    } catch (error) {
      console.log(error);
    }
  };
  colNUI = (title, name) => (
    <Column
      title={title}
      dataIndex={name}
      sorter={(a, b) => a[name] - b[name]}
    />
  );
  colUI = (title, name) => (
    <Column
      title={title}
      dataIndex={name}
      sorter={(a, b) => a[name].localeCompare(b[name])}
    />
  );
  columnUI = () => {
    const { type, showAction, masterNo } = this.props;
    try {
      return (
        <>
          <Column
            width={50}
            title={PageConst.no}
            dataIndex={"sr"}
            sorter={(a, b) => a.sr - b.sr}
          />
          {type === "master" && (
            <>
              {masterNo !== 7 && this.colUI(masterConst[masterNo], "mname")}
              {masterNo === 6 && this.colUI(PageConst.kra, "kraname")}
              {masterNo === 7 && this.colUI(PageConst.per, "periodname")}
            </>
          )}
          {type === "emp" && (
            <>
              {this.colUI(PageConst.empCode, "empcode")}
              {this.colUI(PageConst.empName, "name")}
              {this.colUI(PageConst.des, "designation")}
              {this.colUI(PageConst.dept, "department")}
              {this.colUI(PageConst.email, "email")}
              {this.colUI(PageConst.mob, "mobileno")}
            </>
          )}
          {type === "empApp" && (
            <>
              {this.colUI(PageConst.empName, "name")}
              {this.colUI(PageConst.dept, "department")}
              {this.colUI(PageConst.des, "designation")}
              {this.colUI(PageConst.sdate, "startdate")}
              {this.colUI(PageConst.edate, "enddate")}
              <Column
                width={150}
                title={PageConst.action}
                render={(record, i) => (
                  <div className="actionUI" key="master">
                    {record.giveappraisal !== 0 && role !== 1 && (
                      <Popover content={PageConst.give} trigger="hover">
                        <div
                          className="actionDiv"
                          onClick={() =>
                            this.props.rediect(
                              2,
                              record.empid,
                              record.name,
                              record.appweight
                            )
                          }
                        >
                          <ScheduleOutlined />
                        </div>
                      </Popover>
                    )}
                    {record.viewappraisal !== 0 && (
                      <Popover content={PageConst.vj} trigger="hover">
                        <div
                          className="actionDiv"
                          onClick={() =>
                            this.props.rediect(
                              3,
                              record.empid,
                              record.name,
                              record.viewappraisal
                            )
                          }
                        >
                          <EyeOutlined />
                        </div>
                      </Popover>
                    )}
                  </div>
                )}
              />
            </>
          )}
          {type === "reportAdmin" && (
            <>
              {this.colUI(PageConst.empCode, "empcode")}
              {this.colUI(PageConst.name, "fullname")}
              {this.colUI(PageConst.dept, "department")}
              {this.colUI(PageConst.des, "designation")}
              {this.colNUI(PageConst.finScor, "finalscore")}
              <Column
                width={150}
                title={PageConst.action}
                render={(record, i) => (
                  <div className="actionUI" key="master">
                    <div
                      className="actionDiv"
                      onClick={() =>
                        this.props.history.push(
                          "/report/view/" + window.btoa(record.empid)
                        )
                      }
                    >
                      <EyeOutlined />
                    </div>
                  </div>
                )}
              />
            </>
          )}
          {type === "empReport" && (
            <>
              {this.colUI(PageConst.rPreiod, "reviewperiodname")}
              {this.colUI(PageConst.year, "yearname")}
              {this.colNUI(PageConst.obtScore, "score")}
              <Column
                width={150}
                title={PageConst.action}
                render={(record, i) => (
                  <div className="actionUI" key="master">
                    <div
                      className="actionDiv"
                      onClick={() => {
                        localStorage.setItem(
                          "yearAndPeriod",
                          JSON.stringify({
                            yerID: record.yearid,
                            periodID: record.reviewperiodid,
                          })
                        );
                        this.props.history.push(
                          "/report/view/" + window.btoa(record.empid)
                        );
                      }}
                    >
                      <EyeOutlined />
                    </div>
                  </div>
                )}
              />
            </>
          )}
          {showAction && (
            <Column
              width={150}
              title={PageConst.action}
              render={(record, i) => this.actionUi(record, type)}
            />
          )}
        </>
      );
    } catch (error) {
      console.log(error);
    }
  };
  searchData = () => {
    try {
      const { data, searchVal } = this.props;
      if (searchVal && searchVal !== "") {
        let searchData = [];
        let display = [];
        data?.forEach((a) => {
          display.push(
            a.empcode,
            a.fullname,
            a.department,
            a.designation,
            a.mname,
            a.kraname,
            a.periodname,
            a.name,
            a.startdate,
            a.enddate
          );
          let array = [];
          display.forEach((e) => {
            if (e && e !== null && e.toString().length > 0) array.push(e);
          });
          let matches = array.filter((s) =>
            s
              .toString()
              .toLowerCase()
              .includes(searchVal.toString().toLowerCase())
          );
          display = [];
          if (matches && matches.length > 0) searchData.push(a);
        });
        return searchData;
      } else {
        return data;
      }
    } catch (error) {
      console.log(error);
    }
  };
  render() {
    const { pagination } = this.state;
    const { data, footerUI, custmPagi, handleCustm, searchVal ,type } = this.props; // data?.forEach((a,i) => {if(!a.sr)a.sr=i+1});
    let display = searchVal ? this.searchData() : data;
    display?.forEach((a, i) => {
      a.sr = custmPagi
        ? (custmPagi.current > 1
            ? (custmPagi.current - 1) * custmPagi.pageSize
            : custmPagi.current - 1) +
          (i + 1)
        : i + 1;
    });
    return (
      <TableStyle>
        <Table
          dataSource={display}
          pagination={
            custmPagi ? custmPagi : display?.length > 10 ? pagination : false
          }
          onChange={custmPagi ? handleCustm : this.handleTable}
          rowClassName={(record) =>`anime ${record.isactive === 1 &&type ==="emp"? "rowDeactive" : ""}`}
          onRow={(record) => {
            return {
              // onDoubleClick: (event) => type === "emp" && this.props.redirect(),
            };
          }}
          footer={footerUI}
          showSorterTooltip={false}
        >
          {this.columnUI()}
        </Table>
      </TableStyle>
    );
  }
}
export default withRouter(TableUI);
