import React, { Component } from "react";
import { connect } from "react-redux";
import { Spin } from "antd";
import { withRouter } from "react-router-dom";
import { SearchOutlined } from "@ant-design/icons";

import { EmAppContainer } from "./style.js";
import { EmAppConst } from "./constant";
import AddEmployeeAppraisal from "../AddEmployeeAppraisal";
import JuniorAppraisal from "../JuniorAppraisal";
import { getOrgId, getAuthUserID } from "modules/helper.js";
import { getYearReview } from "redux/organization/action";
import { getEmpAppbyId } from "redux/employee/action";
import { Row, Col, Label, Select, Table, Input } from "components/Form";
var orgID = getOrgId();
var userID = getAuthUserID();
class Employee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      year: "",
      yearID: 0,
      period: "",
      periodID: 0,
      yearSelect: [],
      periodSelect: [],
      pageCount: 1,
      passData: {},
      searchVal: "",
    };
  }
  async componentDidMount() {
    try {
      orgID = getOrgId();
      userID = getAuthUserID();
      await this.props.getYearReview(orgID);
    } catch (error) {
      console.log(error);
    }
  }
  componentDidUpdate(prevProps) {
    const { yearReview } = this.props;
    if (prevProps.yearReview !== yearReview) {
      let yData = [];
      yearReview?.forEach((e) => {
        yData.push({ id: e.id, value: e.yearname });
      });
      this.setState({ yearSelect: yData });
    }
  }
  setPeriodData = (yearID) => {
    try {
      const { yearReview } = this.props;
      let pData = [];
      if (yearID !== 0) {
        let data = yearReview.find((x) => x.id === yearID);
        data?.reviewPeriods?.forEach((y) => {
          pData.push({ id: y.id, value: y.periodname });
        });
      }
      this.setState({ periodSelect: pData });
    } catch (error) {
      console.log(error);
    }
  };
  selectUI = (val, name, dis) => {
    try {
      const { yearSelect, periodSelect, yearID } = this.state;
      return (
        <Select
          withID
          noSort
          data={name === "year" ? yearSelect : periodSelect}
          value={val}
          defaultValue={val}
          disabled={dis}
          onChange={async (value, data) => {
            if (name === "year") {
              await this.props.getYearReview(orgID);
              this.setPeriodData(data.id);
              this.setState({
                year: value,
                yearID: data.id,
                period: "",
                periodID: 0,
              });
            } else if (name === "period") {
              if (yearID !== 0) {
                // localStorage.setItem(
                //   "setSaveApp",
                //   JSON.stringify({
                //     yearid: yearID,
                //     reviewperiodid: data.id,
                //   })
                // );
                this.props.getEmpAppbyId(
                  userID + "/" + yearID + "/" + data.id + "/" + orgID
                );
              }
              this.setState({
                period: value,
                periodID: data.id,
              });
            }
            this.setState({searchVal:""});
          }}
        />
      );
    } catch (error) {
      console.log(error);
    }
  };
  rediect = (a, b, c, d) => {
    try {
      const { yearID, periodID } = this.state;
      let impdata = {
        empid: b,
        empName: c,
        viewappraisal: d,
        appweight:d,
        yearid: yearID,
        reviewperiodid: periodID,
      };
      this.setState({ passData: impdata, pageCount: a ? a : 1 });
      // let d = window.btoa(b);
      // let e = c ? "/" + window.btoa(c) : "";
      // this.props.history.push(a + d + e);
    } catch (error) {
      console.log(error);
    }
  };
  searchChange = (val) => this.setState({ searchVal: val.target.value });
  render() {
    const { year, period, pageCount, passData, searchVal } = this.state;
    const { empAppData, loading } = this.props;
    return (
      <Spin spinning={loading} size="large">
        {pageCount === 1 && (
          <EmAppContainer>
            <div className="secDiv">
              <div className="headDiv">
                <h2>{EmAppConst.head}</h2>
              </div>
              <div className="pDiv">
                <Row gutter={20} className="rowHeader">
                  <Col xs={24} sm={12} md={12} lg={6} xl={6}>
                    <Label title={EmAppConst.year} />
                    {year === "" && this.selectUI("", "year", false)}
                    {year !== "" && this.selectUI(year, "year", false)}
                  </Col>
                  <Col xs={24} sm={12} md={12} lg={6} xl={6}>
                    <Label title={EmAppConst.period} />
                    {period === "" && this.selectUI("", "period", year === "")}
                    {period !== "" &&
                      this.selectUI(period, "period", year === "")}
                  </Col>
                  <Col xs={24} sm={12} md={12} lg={6} xl={6}>
                    <Label title={EmAppConst.search} />
                    <Input
                      value={searchVal}
                      suffix={<SearchOutlined />}
                      onChange={this.searchChange}
                    />
                  </Col>
                </Row>
                <Table
                  data={period !== "" ? empAppData : []}
                  type="empApp"
                  rediect={this.rediect}
                  searchVal={searchVal}
                />
              </div>
            </div>
          </EmAppContainer>
        )}
        {pageCount === 2 && (
          <AddEmployeeAppraisal passData={passData} setPage={this.rediect} />
        )}
        {pageCount === 3 && (
          <JuniorAppraisal passData={passData} setPage={this.rediect} />
        )}
      </Spin>
    );
  }
}
const mapStateToProps = (state) => ({
  loading: state.employee.loading,
  yearReview: state.organization.yearReview,
  empAppData: state.employee.empAppData,
});
const mapDispatchToProps = (dispatch) => ({
  getYearReview: (payload) => dispatch(getYearReview(payload)),
  getEmpAppbyId: (payload) => dispatch(getEmpAppbyId(payload)),
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Employee)
);
