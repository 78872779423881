const HomeConst = {home:'Home'}
const EmpConst = [
  { txt: "Employee", link: "/employee" },
  // { txt: "Basic Profile", link: "/profile" },
  { txt: "Master", link: "/master" },
  { txt: "Appraisal", link: "/appraisal" },
  { txt: "Report", link: "/report" },
  // { txt: "Report", link: "/report" },
];

export { HomeConst,EmpConst };
