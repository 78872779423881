const EmApp = {
    kra: "KRA Type",
    subkra: "KRA",
    kpi: "KPI",
    score: "Score",
    upload: "Document",
    remark: "Remark",
    remarks: "Remarks",
    remarkAlert:
        "Any other remarks, noteworthy effort that should go in the records.",
    head: " Appraisal",
    smscm:"Somewhere Score Is missing!",
}
const kra = ["KRA Type 1", "KRA Type 2"];
const TData = [
    {
        sr: 1,
        kra: "Expansion",
        kpi: "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to.",
        score: 4,
        doc: true,
    },
    {
        sr: 2,
        kra: "Profitability",
        kpi: "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to.",
        score: 3,
        doc: false,
    },
    {
        sr: 3,
        kra: "Team Retention till GM Level + Team Engagement",
        kpi: "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to.",
        score: 2,
        doc: true,
    },
];

export { EmApp, kra, TData };


