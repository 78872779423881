import React, { Component } from "react";
import { Spin } from "antd";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";

import { StyleContainer } from "./style.js";
import Employee from "./page/Employee";
import Appraiser from "./page/Appraiser";
import KraKpi from "./page/KraKpi";
import { EmpConst } from "./constant";
import { Label, Input, Select, Checkbox } from "components/Form";
class EmployeeAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      check: [],
      data: {},
      basicData: {},
      count: 0,
      increse: 1,
      btnDisable: false,
      selfapp: false,
      show: true,
      weightche: true,
      admin: false,
      kraVal: {},
    };
  }
  onChange = (val) => {
    try {
      const { increse } = this.state;
      this.setState({
        increse: val ? increse + 1 : increse !== 1 ? increse - 1 : increse,
      });
    } catch (error) {
      console.log(error);
    }
  };
  setCount = (a, val) => {
    const { count } = this.state;
    this.setState({ count: a ? count + 1 : count, selfapp: val ? val : false });
  };
  header = () => {
    try {
      const { check, weightcheck } = this.state;
      return (
        <>
          <h4>KRA Type 1</h4>
          <span className="set">
            <Checkbox
              text="Weightage"
              checked={check.includes(3)}
              // onChange={() => this.checkClick(3)}
            />
            {weightcheck ? (
              <>
                <Input
                  number
                  // onChange={handleChange}
                />
              </>
            ) : null}
          </span>
        </>
      );
    } catch (error) {
      console.log(error);
    }
  };
  appraisalSubmit = async (val, { setSubmitting }) => {
    try {
      console.log("value", val);
      setSubmitting(false);
    } catch (error) {
      console.log(error);
    }
  };
  viewEmp = () => {
    try {
      return <></>;
    } catch (error) {
      console.log(error);
    }
  };
  selectCodeUI = (name, title, data, val, setFieldValue, error) => {
    try {
      // const { sdata, ldata } = this.state;
      return (
        <>
          <Label title={title} className={this.er(error)} />
          <Select
            data={data}
            value={val}
            withID={true}
            defaultValue={val}
            onChange={(value, data) => {
              setFieldValue(name, value);
              if (name === "code") setFieldValue("name", value);
            }}
            className={error ? "empty" : ""}
          />
        </>
      );
    } catch (error) {
      console.log(error);
    }
  };
  er = (r) => (r ? "empty" : "");
  render() {
    const { match, loading, orgLoading } = this.props;
    const { count, selfapp } = this.state;
    return (
      <Spin spinning={loading || orgLoading} size="large">
        <StyleContainer>
          <div className="secDiv">
            <div className="headDiv">
              <h2>
                {count === 0
                  ? match.params.id
                    ? EmpConst.editAe
                    : EmpConst.ae
                  : count === 1
                  ? match.params.id
                    ? EmpConst.eea
                    : EmpConst.aea
                  : match.params.id
                  ? EmpConst.eekk
                  : EmpConst.aekk}
              </h2>
              <div
                className="btnDiv"
                onClick={() => this.props.history.push("/employee")}
              >
                <LeftOutlined />
              </div>
            </div>
            <div className="pDiv">
              {count === 0 && <Employee setCount={this.setCount} />}
              {count === 1 && (
                <Appraiser setCount={this.setCount} selfapp={selfapp} />
              )}
              {count === 2 && <KraKpi />}
            </div>
          </div>
        </StyleContainer>
      </Spin>
    );
  }
}
const mapStateToProps = (state) => ({
  loading: state.employee.loading,
  orgLoading: state.organization.loading,
});
const mapDispatchToProps = (dispatch) => ({});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EmployeeAdd)
);
