const PageConst = {
  action: "Action",
  no: "No.",
  weight: "Weightage (%)",
  weights: "Weightage",
  name: "Name",
  des: "Designation",
  dept: "Department",
  com: "Company",
  per: "Period",
  kra: "KRA",
  kraType: "KRA Type",
  kpi: "KPI",
  date: "Date",
  tmPr: "Time Period",
  empCode: "Employee Code",
  empName: "Name",
  score: "Score (0-5)",
  scores: "Score",
  scEr: "Score Eraned",
  per: "Period",
  goal: "Goal",
  type: " Type",
  sco1: "Employee1",
  sco2: "Employee2",
  finScor: "Final Score",
  doc: "Document",
  email: "Email",
  mob: "Mobile No.",
  he: "Hierarchy",
  location: "Location",
  zone: "Zone",
  vertical: "Vertical",
  give: "Give Appraisal",
  sdate: "Start date",
  edate: "End date",
  vj: "View Appraisal",
  year: "Year",
  rPreiod: "Review Preiod",
  obtScore: "Obtained Score",
};
const masterConst={0:"Designation",1:"Department",2:"Location",3:"Zone",4:"Vertical",5:"KRA Type",6:"KRA Type"};
export { PageConst,masterConst };
