const EmpViewConst = {
    le:"List of Employees",
    empView:"Employee View",
    colon:" :",
    empCode:"Employee Code",
    firstNM:"First Name",
    midNM:"Middle Name",
    lastNM:"Last Name",
    gender:"Gender",
    dpt:"Department",
    desg:"Designation",
    moNo:"Mobile Number",
    email:"Email",
    repoTo:"Report To",
    loc:"Location",
    zone:"Zone",
    vrt:"Vertical",
    slApp:"Self Appraiser",
    admin:"Admin",
    basDet:"Basic Details",
    appreser:"Appraiser",
    kraKpi:"KRA & KPI",
    name:"Name",
    hirarchy:"Hierarchy",
    wght:"Weightage",
    kra:"KRA",
    kpi:"KPI",
    role:"Role",
    // pass:"Password",
}
const Gender = { 1: "Male", 2: "Female", 3: "Other" }
const Role = { 2: "Admin", 3: "HR", 4: "Employee" }
export { EmpViewConst,Gender,Role };  