import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { LeftOutlined, DownloadOutlined } from "@ant-design/icons";

import { reportConst } from "./constant";
import { Collapse } from "components/Form";
import { getOrgId } from "modules/helper";
import { getSettings } from "redux/organization/action";
import { getSummaryDetails } from "redux/employee/action";
import { configVar, masterConst } from "modules/config";
var orgID = getOrgId();

class ViewDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mainDetails: [],
    };
  }
  async componentDidMount() {
    try {
      const { match } = this.props;
      orgID = getOrgId();
      // role = getAuthRole();
      let locData = JSON.parse(localStorage.yearAndPeriod);
      await this.props.getSettings(orgID);
      await this.props.getSummaryDetails(
        locData.yerID +
          "/" +
          locData.periodID +
          "/" +
          parseInt(window.atob(match.params.id))
      );
    } catch (error) {
      console.log(error);
    }
  }
  async componentDidUpdate(prevProps) {
    try {
      const { summaryDetails } = this.props;
      if (summaryDetails !== prevProps.summaryDetails) {
        let collectData = [];
        summaryDetails?.appraisers?.forEach((a, i) => {
          let check =
            collectData.length > 0
              ? collectData.findIndex((x) => x.level === a.level)
              : -1;
          if (check > -1) {
            collectData[check].coll?.push(a);
          } else {
            let data = {
              level: a.level,
              coll: [a],
            };
            collectData.push(data);
          }
        });
        this.setState({ mainDetails: collectData });
      }
    } catch (error) {
      console.log(error);
    }
  }
  docDownload = (doc) => {
    try {
      let txt = doc.replace("/", "%5C");
      let downURL = configVar.BASE_URL + masterConst.DOWNLOAD_DOC + txt;
      var win = window.open(downURL, "_blank");
      win.focus();
    } catch (error) {
      console.log(error);
    }
  };
  collapseUI = () => {
    try {
      const { mainDetails } = this.state;
      let arrayUI = [];
      mainDetails?.forEach((a) => {
        arrayUI.push({
          header: (
            <div className="colHeadDiv">
              <h4>{reportConst.level + a.level}</h4>
            </div>
          ),
          body: this.nestedColl(a.coll),
        });
      });
      if (arrayUI.length > 0) {
        return <Collapse data={arrayUI} />;
      }
    } catch (error) {
      console.log(error);
    }
  };
  nestedColl = (data) => {
    try {
      const { settingsDetails } = this.props;
      let arrayUI = [];
      data?.forEach((a) => {
        arrayUI.push({
          header: (
            <div className="colHeadDiv">
              <div className="textDiv">
                <h4>{a.appname}</h4>
                <h4 className="weightDiv">{`${reportConst.weight} : ${a.appweight}`}</h4>
              </div>
            </div>
          ),
          body: (
            <>
              {a.ktwsummary?.map((x) => (
                <>
                  <div className="textDiv">
                    <h4>{x.kratypename}</h4>
                    <h4 className="weightDiv">{`${reportConst.weight} : ${x.kratypeweight}`}</h4>
                  </div>
                  <hr />
                  <div className="collTabDiv">
                    <table>
                      <thead>
                        <tr>
                          <th>{reportConst.kra}</th>
                          <th>{reportConst.kpi}</th>
                          <th>{reportConst.weight}</th>
                          <th>
                            {reportConst.obtOutOf + settingsDetails.points}
                          </th>
                          <th>{reportConst.finalScor}</th>
                          <th>{reportConst.remark}</th>
                          <th className="sizDiv">{reportConst.document}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {x.kpisummary?.map((y, i) => (
                          <tr key={i}>
                            <td>{y.kraname}</td>
                            <td>{y.kpiname}</td>
                            <td>{y.kpiweight}</td>
                            <td>{y.score}</td>
                            <td>{y.appscore}</td>
                            <td>{y.remarks}</td>
                            <td>
                              {y.docupload !== "" && (
                                <DownloadOutlined
                                  onClick={() => this.docDownload(y.docupload)}
                                />
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td></td>
                          <td></td>
                          <td className="totalDiv">
                            {`${x.totalkpipercentage} `}
                          </td>
                          <td></td>
                          <td className="totalDiv">{`${x.totalkpiscore}`}</td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </>
              ))}
              {a?.remarks && (
                <>
                  <h4>{reportConst.remark}</h4>
                  <hr />
                  <div className="remarkDiv">{a?.remarks}</div>
                </>
              )}
            </>
          ),
        });
      });
      if (arrayUI.length > 0) {
        return <Collapse data={arrayUI} />;
      }
    } catch (error) {
      console.log(error);
    }
  };
  render() {
    const { setView, summaryDetails, name } = this.props;
    return (
      <div className="secDiv">
        <div className="headDiv">
          <h2>{name + " Appraiser"}</h2>
          <div className="btnDiv" onClick={() => setView(0)}>
            <LeftOutlined />
          </div>
        </div>
        {this.collapseUI()}
        <div className="bottomDiv">
          {reportConst.tot + summaryDetails.totalscore}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  summaryDetails: state.employee.summaryDetails,
  settingsDetails: state.organization.settingsDetails,
});
const mapDispatchToProps = (dispatch) => ({
  getSummaryDetails: (id) => dispatch(getSummaryDetails(id)),
  getSettings: (id) => dispatch(getSettings(id)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ViewDetail)
);
