const PageConst = {
  cp: "Forget Password",
  mobile: "Mobile number*",
  email:"Email*",
  getOtp: "get OTP",
  otp: "OTP*",
  newpwd: "New Password*",
  conpwd: "Conform New Password*",
  RepatePWD: "Both 'New Password' & 'Conform New Password' need to be the same.",
};

export { PageConst };
