import React, { Component } from "react";
import { message, Spin } from "antd";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  PlusCircleOutlined,
  CloseOutlined,
  LeftOutlined,
} from "@ant-design/icons";

import { EmApp } from "./constant";
import { AddAppContainer } from "./style.js";
import { Collapse, Input, Label, Button, FileUpload } from "components/Form";
import {
  getKraByEmpId,
  saveAppraisal,
  getEmpAppbyId,
  getWeightage,
} from "redux/employee/action";
import { getSettings } from "redux/organization/action";
import { getAuthUserID, getOrgId } from "modules/helper";
import { ButtonConst } from "App/AppConstant";

var orgID = getOrgId();
var userID = getAuthUserID();
class AddEmployeeAppraisal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      remarks: "",
      btnDisable: false,
      kraKpiData: [],
      activeKey: ["1"] ,
    };
  }
  async componentDidMount() {
    try {
      const { passData } = this.props;
      userID = getAuthUserID();
      orgID = getOrgId();
      await this.props.getSettings(orgID);
      await this.props.getKraByEmpId(passData.empid);
      await this.props.getWeightage(passData.empid);
    } catch (error) {
      console.log(error);
    }
  }
  componentDidUpdate(prevProps) {
    try {
      const { weightageData } = this.props;

      if (prevProps.weightageData !== weightageData) {
        let array = [];
        weightageData?.forEach((a, i) => {
          let kraData = {
            wid: a.wid,
            kratypeid: a.kratypeid,
            kratypename: a.kratypename,
            kpilists: [],
          };
          a.kpiweightages?.forEach((x, i) => {
            kraData?.kpilists.push({
              kid: x.kid,
              kpiname: x.kpiname,
              kraid: x.kraid,
              kraname: x.kraname,
              wid: x.wid,
              kpiweight: x.kpiweight,
              kratypeweight: a.kratypeweight,
              score: null,
              doc: "",
              remarks: "",
            });
          });
          array.push(kraData);
        });
        this.setState({ kraKpiData: array });
      }
    } catch (error) {
      console.log(error);
    }
  }
  handleText = (val) => this.setState({ remarks: val.target.value });
  handleInput = (val, callId, trId) => {
    try {
      const { kraKpiData } = this.state;
      kraKpiData[callId].kpilists[trId].score =
        val && parseFloat(val.toFixed(2));
      this.setState({ kraKpiData });
    } catch (error) {
      console.log(error);
    }
  };
  handleRemark = (val, callId, trId) => {
    try {
      const { kraKpiData } = this.state;
      kraKpiData[callId].kpilists[trId].remarks = val.target.value;
      this.setState({ kraKpiData });
    } catch (error) {}
  };
  fileUpload = (val, kraID, kpiId) => {
    try {
      if (val !== "") {
        return (
          <span className="optionui">
            <span className="txtWrap">{val.split("/")[1]}</span>
            <CloseOutlined onClick={() => this.remove(kraID, kpiId)} />
          </span>
        );
      }
      return (
        <FileUpload
          accept=".jpg,.jpeg,.png,.svg,.pdf"
          image={true}
          kraID={kraID}
          kpiId={kpiId}
          setPath={this.setPath}
          elements={<PlusCircleOutlined />}
        />
      );
    } catch (error) {
      console.log(error);
    }
  };
  setPath = (data) => {
    try {
      const { kraKpiData } = this.state;
      let kraId = kraKpiData.findIndex((x) => x.kratypeid === data.ktid);
      let kpiId = kraKpiData[kraId]?.kpilists.findIndex(
        (x) => x.kid === data.kpiid
      );
      kraKpiData[kraId].kpilists[kpiId].doc = data.path;
      this.setState({ kraKpiData });
    } catch (error) {
      console.log(error);
    }
  };
  remove = (kraID, kpiId) => {
    try {
      const { kraKpiData } = this.state;
      let kraid = kraKpiData.findIndex((x) => x.kratypeid === kraID);
      let kpiid = kraKpiData[kraid]?.kpilists.findIndex((x) => x.kid === kpiId);
      kraKpiData[kraid].kpilists[kpiid].doc = "";
      this.setState({ kraKpiData });
    } catch (error) {
      console.log(error);
    }
  };
  collapseUI = () => {
    try {
      const { kraKpiData } = this.state;
      const { settingsDetails } = this.props;
      let arrayUI = [];
      kraKpiData?.forEach((a, callIndx) => {
        arrayUI.push({
          header: (
            <div className="colHeadDiv">
              <h4>{a.kratypename}</h4>
            </div>
          ),
          body: (
            <>
              <hr />
              <div className="tableView">
                <table>
                  <thead>
                    <tr>
                      <th>{EmApp.subkra}</th>
                      <th>{EmApp.kpi}</th>
                      <th className="scoreDiv">{`${EmApp.score} (0- ${settingsDetails.points})`}</th>
                      <th className="scoreDiv">{EmApp.remarks}</th>
                      <th className="scoreDiv">{EmApp.upload}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {a.kpilists?.map((x, trIndx) => (
                      <tr key={trIndx}>
                        <td>{x.kraname}</td>
                        <td>{x.kpiname}</td>
                        <td>
                          <Input
                            num={settingsDetails.points}
                            number
                            defaultVal
                            value={x.score}
                            handleChange={(val) =>
                              this.handleInput(val, callIndx, trIndx)
                            }
                          />
                        </td>
                        <td>
                          <Input
                            value={x.remarks}
                            handleChange={(val) =>
                              this.handleRemark(val, callIndx, trIndx)
                            }
                          />
                        </td>
                        <td>{this.fileUpload(x.doc, a.kratypeid, x.kid)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          ),
        });
      });

      if (arrayUI.length > 0) {
        return <Collapse data={arrayUI} collapseKey={this.collapseKey} defaultActiveKey={this.state.activeKey}/>;
      }
    } catch (error) {
      console.log(error);
    }
  };
  collapseKey = (key) => this.setState({ activeKey: key });
  submitData = async () => {
    try {
      const { kraKpiData, remarks } = this.state;
      const { passData, settingsDetails } = this.props;
      this.setState({ btnDisable: true });
      setTimeout(() => {
        this.setState({ btnDisable: false });
      }, 4500);
      let flag = kraKpiData.length > 0 ? false : true;
      if (!flag) {
        let postData = {
          apprid: 0,
          empid: passData.empid,
          appid: userID,
          appweight: passData.appweight,
          maxscore: settingsDetails.points,
          apptotalscore: 0,
          maxscorecanget: 0,
          remarks: remarks.trim(),
          yearid: passData.yearid,
          reviewperiodid: passData.reviewperiodid,
          points: [],
        };
        kraKpiData?.forEach((a) => {
          a.kpilists?.forEach((x) => {
            flag = x.score === null;
            postData?.points.push({
              pid: 0,
              kid: x.kid,
              kpiweight: x.kpiweight,
              kratypeweight: x.kratypeweight,
              kratypescore: 0,
              kpiscore: 0,
              score: x.score,
              docupload: x.doc,
              remarks: x.remarks,
              appscore: 0,
              appmaxscore: 0,
            });
          });
        });
        let chek = postData?.points.findIndex((c) => c.score === null);
        if (chek > -1) {
          flag = true;
          message.error(EmApp.smscm);
        }
        if (!flag) {
          await this.props.saveAppraisal(postData);
          console.log(postData);
          await this.props.getEmpAppbyId(
            `${userID}/${passData.yearid}/${passData.reviewperiodid}/${orgID}`
          );
          this.setPrevPage();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  setPrevPage = () => {
    try {
      const { isAdded } = this.props;
      isAdded && this.props.setPage();
    } catch (error) {}
  };
  render() {
    const { btnDisable, remarks } = this.state;
    const { passData } = this.props;
    return (
      <AddAppContainer>
        <div className="secDiv">
          <div className="headDiv">
            <h2>{passData.empName + EmApp.head}</h2>
            <div className="btnDiv" onClick={() => this.props.setPage()}>
              <LeftOutlined />
            </div>
          </div>
          {this.collapseUI()}
          <div className="remark">
            <Label title={EmApp.remark} />
            <Input
              className="input"
              row={8}
              value={remarks}
              handleChange={this.handleText}
              max={1000}
            />
            <div>{EmApp.remarkAlert}</div>
          </div>
          <div className="btnDiv">
            <div className="btn anime">
              <Button
                className="nextBtn"
                disabled={btnDisable}
                onClick={this.submitData}
              >
                {ButtonConst.submit}
              </Button>
            </div>
          </div>
        </div>
      </AddAppContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.employee.loading,
  isAdded: state.employee.isAdded,
  kraByEmpId: state.employee.kraByEmpId,
  weightageData: state.employee.weightageData,
  settingsDetails: state.organization.settingsDetails,
});
const mapDispatchToProps = (dispatch) => ({
  getKraByEmpId: (payload) => dispatch(getKraByEmpId(payload)),
  getWeightage: (payload) => dispatch(getWeightage(payload)),
  saveAppraisal: (payload) => dispatch(saveAppraisal(payload)),
  getEmpAppbyId: (payload) => dispatch(getEmpAppbyId(payload)),
  getSettings: (id) => dispatch(getSettings(id)),
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddEmployeeAppraisal)
);
