import styled from "styled-components";

const SettingContainer = styled.div`
  .button {
    margin-left: auto;
  }
  Label {
    padding-top: 10px;
  }
  .pick,
  .switch,
  .number {
    margin: 10px 0;
  }
  .empty {
    border: 2px red !important;
  }
`;
export { SettingContainer };
