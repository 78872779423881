import styled from "styled-components";
import { size } from "App/device";
import { Theme } from "App/theme";
const StyleContainer = styled.div`
  .countDiv {
    span {
      margin: 0.5em;
      font-size: large;
      padding-top: 10px;
      @media ${size["mobile-md-max"]} {
          margin: 0.4em;  
       }
    }
    .actDiv:first-child {
      margin-left: 0;
      span {
        margin-left: 0;
      }
    }
  }
  .weight {
    margin-bottom: 8px;
  }
  .btnDisplay {
    display: flex;
    align-items: center;
    button {
      margin-left: 0;
      margin-right: 1em;
    }
  }
  .set {
    display: flex;
    margin-left: auto;
    .setLa {
      padding: 6px;
    }
  }
.pDiv {
    .apraiserForm {
      align-items: center;
    }
    .chekBoxDIv{
      margin-top: 2em;
      @media ${size["tablet-md-max"]} {
        margin-top: 0em;
      }
    }
    .aprsTable {
      overflow-x: auto;
      .aprsDiv {
        border-collapse: collapse;
        border-spacing: 0;
        width: 100%;
        border: 1px solid #ddd;
        margin-top: 1em;
        @media ${size["tablet-max"]} {
          min-width: 52em;
        }
        th,
        td {
          text-align: left;
          padding: 16px;
        }
        thead {
          background-color: #f2f2f2;
        }
        tbody tr:nth-child(even) {
          background-color: #f2f2f2;
        }
        tfoot {
          font-weight: bold;
        }
        .actionTD span{
          margin: 0 .5em;
          cursor: pointer;
        }
      }
    }
    .kpiHeadDiv {
      margin-bottom: 1em;
      .ant-col {
        display: flex;
        align-items: center;
        .addBtnDiv button {
          margin-top: 0;
        }
        .addBtnDiv button:first-child {
          margin-right: 1em;
        }
      }
    }
    .bottomDiv {
      display: flex;
      align-items: center;
      .bottText {
        font-weight: bold;
        padding-top: ;
      }
      .bottomBtn {
        margin-left: auto;
        button {
          margin-top: 0;
        }s
      }
    }
  .cusCollaps{
    box-shadow: 0px 0px 7px rgb(0 0 0 / 15%);
    border-radius: 10px;
    margin-bottom: 2em;
    .collheader{
        display: flex;
        padding: 15px 10px;
        cursor: pointer;
        h4{
          margin-bottom:0;
        }
        .textDiv {
          display: flex;
          align-items: center;
          width: 100%;
          .weightDiv {
            margin-left: 1em;
          }
          @media ${size["mobile-md-max"]} {
            display: block;
            .weightDiv {
              margin-left: 0;
            }
          }
        }
        .actionDiv {
          display: flex;
          align-items: center;
          margin-bottom: 0.5em;
          margin-left: auto;
          margin-right: 10px;
          .anticon {
            margin-right: 20px;
            @media ${size["mobile-md-max"]}{
              margin-right: 10px;

            }
          }
          .anticon:last-child{
            margin-right: 0px;
          }
        }
      }
      .collBody{
        padding: 0px 10px 10px;
        .btnDiv{
          background-color: ${Theme.mainColor};
          cursor: pointer;
          width: 2em;
          height: 2em;
          margin-left: auto;
          margin-bottom: 0.5em;
          align-items: center;
          justify-content: center;
          stroke: #fff;
          stroke-width: 5em;
          font-size: 1.2em;
          border-radius: 50%;
        }
      }
    }
  }
}
`;
export { StyleContainer };
