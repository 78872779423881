export const SET_AUTH = "SET_AUTH";

export const LOGIN_INITIATED = "LOGIN_INITIATED";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";

export const LOGOUT = "LOGOUT";
export const LOGOUT_ERROR = "LOGOUT_ERROR";
export const LOGOUT_INITIATED = "LOGOUT_INITIATED";

export const GET_OTP_INITIATED = "GET_OTP_INITIATED";
export const GET_OTP_SUCCESS = "GET_OTP_SUCCESS";
export const GET_OTP_ERROR = "GET_OTP_ERROR";

export const FORGET_PASSWORD_INITIATED = "FORGET_PASSWORD_INITIATED";
export const FORGET_PASSWORD_SUCCESS = "FORGET_PASSWORD_SUCCESS";
export const FORGET_PASSWORD_ERROR = "FORGET_PASSWORD_ERROR";

export const CHANGE_PASSWORD_INITIATED = "CHANGE_PASSWORD_INITIATED";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_ERROR = "CHANGE_PASSWORD_ERROR";
