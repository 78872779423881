import React, { Component } from "react";
import Workbook from "react-excel-workbook";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { SearchOutlined } from "@ant-design/icons";
import { DownloadOutlined } from "@ant-design/icons";

import { ReportStyle } from "./style";
import { reportConst, reportArray, empRepoArray } from "./constant";
import { Row, Col, Label, Table, Select, Button, Input } from "components/Form";
import { getYearReview } from "redux/organization/action";
import { getReportList, getOwnAppraisal } from "redux/employee/action";
import { getOrgId, getAuthRole, getAuthUserID } from "modules/helper";
var orgID = getOrgId();
var role = getAuthRole();
var userID = getAuthUserID();
class Reports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      year: "",
      yearID: 0,
      yearError: false,
      period: "",
      periodID: 0,
      periodError: false,
      yearSelect: [],
      periodSelect: [],
      searchVal: "",
    };
  }
  async componentDidMount() {
    try {
      orgID = getOrgId();
      role = getAuthRole();
      userID = getAuthUserID();
      // localStorage.removeItem("yearAndPeriod");
      await this.props.getYearReview(orgID);
      role === 4 && (await this.props.getOwnAppraisal(userID));
    } catch (error) {
      console.log(error);
    }
  }
  componentDidUpdate(prevProps) {
    const { yearReview } = this.props;
    if (prevProps.yearReview !== yearReview) {
      let yData = [];
      yearReview?.forEach((e) => {
        yData.push({ id: e.id, value: e.yearname });
      });
      this.setState({ yearSelect: yData });
    }
  }
  setPeriodData = (yearID) => {
    try {
      const { yearReview } = this.props;
      let pData = [];
      if (yearID !== 0) {
        let data = yearReview.find((x) => x.id === yearID);
        data?.reviewPeriods?.forEach((y) => {
          pData.push({ id: y.id, value: y.periodname });
        });
      }
      this.setState({ periodSelect: pData });
    } catch (error) {
      console.log(error);
    }
  };
  selectUI = (val, name, dis, error) => {
    try {
      const { yearSelect, periodSelect } = this.state;
      return (
        <Select
          data={name === "year" ? yearSelect : periodSelect}
          value={val}
          defaultValue={val}
          withID
          disabled={dis}
          noSort={name === "period"}
          className={error ? "empty" : ""}
          onChange={async (value, data) => {
            if (name === "year") {
              await this.props.getYearReview(orgID);
              this.setPeriodData(data.id);
              this.setState({
                year: value,
                yearID: data.id,
                yearError: false,
                period: "",
                periodID: 0,
                searchVal: "",
              });
            } else if (name === "period") {
              this.setState({
                period: value,
                periodID: data.id,
                periodError: false,
              });
            }
          }}
        />
      );
    } catch (error) {
      console.log(error);
    }
  };
  searchChange = (val) => this.setState({ searchVal: val.target.value });
  handleApply = async () => {
    try {
      const { year, yearID, period, periodID } = this.state;
      let flag = false;
      if (year === "") {
        flag = true;
        this.setState({
          yearError: true,
        });
      } else if (period === "") {
        flag = true;
        this.setState({
          periodError: true,
        });
      }
      if (!flag) {
        localStorage.setItem(
          "yearAndPeriod",
          JSON.stringify({ yerID: yearID, periodID: periodID })
        );
        await this.props.getReportList(yearID + "/" + periodID);
        // this.setState({searchVal:""})
      }
    } catch (error) {
      console.log(error);
    }
  };
  inside = () => {
    try {
      const { ownAppraisal, reportList } = this.props;
      let repoData = role !== 4 ? reportList : ownAppraisal;
      repoData?.forEach((a, i) => {
        a.no = i + 1;
      });
      return (
        <Workbook.Sheet data={repoData} name={"Employee Summary Report"}>
          {this.workBookColum()}
        </Workbook.Sheet>
      );
    } catch (error) {
      console.log(error);
    }
  };
  workBookColum = () => {
    try {
      let data = role !== 4 ? reportArray : empRepoArray;
      return data?.map((a, i) => (
        <Workbook.Column label={a.label} value={a.key} key={i} />
      ));
    } catch (error) {
      console.log(error);
    }
  };
  render() {
    const { year, yearError, period, periodError, searchVal } = this.state;
    const { ownAppraisal, reportList } = this.props;
    let lng = role !== 4 ? reportList.length : ownAppraisal.length;
    return (
      <ReportStyle>
        <div className="secDiv">
          <div className="headDiv">
            <h2>{reportConst.rep}</h2>
            {role === 4 && lng > 0 && (
              <div className="topBtn">
                <Workbook
                  filename={"report.xlsx"}
                  element={
                    <div className="btnDiv">
                      <DownloadOutlined />
                    </div>
                  }
                >
                  {this.inside()}
                </Workbook>
              </div>
            )}
          </div>
          <div className="pDiv">
            {role !== 4 && (
              <Row gutter={20}>
                <Col xs={24} sm={12} md={12} lg={6} xl={6}>
                  <Label
                    title={reportConst.year}
                    className={yearError && year === "" ? "empty" : ""}
                  />
                  {year === "" &&
                    this.selectUI("", "year", false, yearError && year === "")}
                  {year !== "" && this.selectUI(year, "year", false)}
                </Col>
                <Col xs={24} sm={12} md={12} lg={6} xl={6}>
                  <Label
                    title={reportConst.period}
                    className={periodError && period === "" ? "empty" : ""}
                  />
                  {period === "" &&
                    this.selectUI(
                      "",
                      "period",
                      year === "",
                      periodError && period === ""
                    )}
                  {period !== "" &&
                    this.selectUI(period, "period", year === "")}
                </Col>
                <Col xs={24} sm={12} md={12} lg={6} xl={6}>
                  <Label title={reportConst.search} />
                  <Input
                    value={searchVal}
                    suffix={<SearchOutlined />}
                    onChange={this.searchChange}
                  />
                </Col>
                <Col xs={24} sm={12} md={12} lg={6} xl={6} className="repoBTN">
                  <Button onClick={this.handleApply}>
                    {reportConst.apply}
                  </Button>
                  {lng > 0 && (
                    <Workbook
                      filename={"repert.xlsx"}
                      element={
                        <Button className="downBtn">
                          <DownloadOutlined />
                        </Button>
                      }
                    >
                      {this.inside()}
                    </Workbook>
                  )}
                </Col>
              </Row>
            )}
            <h2 className="summHead anime">{reportConst.sumReport}</h2>
            <div className="tableDiv">
              <Table
                data={role !== 4 ? reportList : ownAppraisal}
                type={role !== 4 ? "reportAdmin" : "empReport"}
                searchVal={searchVal}
              />
            </div>
          </div>
        </div>
      </ReportStyle>
    );
  }
}
const mapStateToProps = (state) => ({
  yearReview: state.organization.yearReview,
  reportList: state.employee.reportList,
  ownAppraisal: state.employee.ownAppraisal,
});
const mapDispatchToProps = (dispatch) => ({
  getYearReview: (id) => dispatch(getYearReview(id)),
  getReportList: (id) => dispatch(getReportList(id)),
  getOwnAppraisal: (id) => dispatch(getOwnAppraisal(id)),
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Reports)
);
