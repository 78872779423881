const LoginConstant = {
  login: "Login",
  forgerPwd: "Forgot Password?",
  donthave: "Don't have an account? ",
  register: "Register here",
  placeholder: "Username or Mobile number",
  passwordplaceholder: "Password",
};

export { LoginConstant };
