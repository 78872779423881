const ProConst = {
  pdetail: "Personal Details",
  emp: "Employee Code",
  flname: "First Name",
  mname: "Middle Name",
  lname: "Last Name",
  email:"Email",
  gen: "Gender",
  dep: "Department",
  desi: "Designation",
  report: "Report To",
  location:"Location",
  vertical:"Vertical",
  zone:"Zone",
  role: "Roles & Rights",
  admin: "Admin",
  app: "Appraisal",
  em: "Employee",
  apr: "Appraisal Approve",
  no: "Number",
  name: "Name",
  weg: "Weightage",
};
const Gender = { 1: "Male", 2: "Female", 3: "Other" }
const ProfileData = [
  { sr: "1", emp: "emp001", name: "Solamon Lavis", weight: "30%" },
  { sr: "2", emp: "emp002", name: "Ket Blanchet", weight: "20%" },
  { sr: "3", emp: "emp003", name: "Mittal Rai", weight: "50%" },
  // { sr: "", emp: "", name: "", weight: "Total: 100%" },
];

export { ProConst, ProfileData ,Gender };
