import styled from "styled-components";
import { size } from "App/device";
const StyleContainer = styled.div`
  .headDiv {
    h2 {
      margin-bottom: 0;
    }
    .actDiv {
      display: flex;
      align-items: center;
      margin-left: auto;
      .searchDiv {
        margin-right: 1em;
      }
    }
    @media ${size["mobile-md-max"]} {
      display: block !important;
      .actDiv {
        .searchDiv {
          margin-left: auto !important;
        }
        .btnDiv {
          margin-left: 0 !important;
        }
      }
    }
  }
`;
export { StyleContainer };
