const MasterConstant = {
  period: "New Period",
  pname: "Period Name",
  year: "Year Selection",
  peselect: "Period Selection",
  month: "Month",
  quart: "Quarter",
  half: "Half-Year",
  years: "Year",
  row:"Row",
  star:"*",
  stDate:"Start Date",
  enDate:"End Date",
};

const TabsConst = [
  {
    mtid: 7,
    mtname: "KRA",
    mtdesc: null,
  },
  {
    mtid: 8,
    mtname: "Period",
    mtdesc: null,
  },
];
const DesDeta = [
  { sr: 1, name: "Marketing Executive" },
  { sr: 2, name: "Office Assistant" },
  { sr: 3, name: "Sr. UI/UX Designer" },
  { sr: 4, name: "Sr. Telemarketing Executive" },
  { sr: 5, name: "Sr. Software Developer (ReactJS)" },
];
const DepartData = [
  { sr: 1, name: "Admin", company: "NBL" },
  { sr: 2, name: "Customer Service", company: "NBL" },
  { sr: 3, name: "Dispatch", company: "NBL" },
  { sr: 4, name: "Human Resources", company: "NBL" },
  { sr: 5, name: "IT", company: "NBL" },
];
const KraTDeta = [
  { sr: 1, name: "KRA Tyep 1" },
  { sr: 2, name: "KRA Tyep 2" },
  { sr: 3, name: "KRA Tyep 3" },
  { sr: 4, name: "KRA Tyep 4" },
  { sr: 5, name: "KRA Tyep 5" },
];
const KraDeta = [
  { sr: 1, name: "KRA Tyep 1", subKra: "Sub Kra 1" },
  { sr: 2, name: "KRA Tyep 2", subKra: "Sub Kra 2" },
  { sr: 3, name: "KRA Tyep 3", subKra: "Sub Kra 3" },
  { sr: 4, name: "KRA Tyep 4", subKra: "Sub Kra 4" },
  { sr: 5, name: "KRA Tyep 5", subKra: "Sub Kra 5" },
];
const locData = [
  { sr: 1, location: "Ahmwdabad" },
  { sr: 2, location: "Surat" },
  { sr: 3, location: "Bhavnagar" },
  { sr: 4, location: "Mehsana" },
  { sr: 5, location: "Div" },
];
const PeriodDeta = [
  { sr: 1, name: "3 Month" },
  { sr: 2, name: "6 Month" },
  { sr: 3, name: "12 Month" },
  { sr: 4, name: "15 Month" },
  { sr: 5, name: "18 Month " },
];
const year = ["2022-2023", "2023-2024", "2024-2025"];
const period = [
  { id: 1, value: "Monthly" },
  { id: 2, value: "Quarterly" },
  { id: 3, value: "Half-yearly" },
  { id: 4, value: "Annually" },
];

const month = ["January","February","March","April","May","June","July","August","September","October","November","December"]

const quartr=["Quarter1","Quarter2","Quarter3","Quarter4"];

const half = ["Half Year 1","Half Year 2"];// {reviewid:0,reviewName:"Half year 2",startDate:"",endDate:""},
const annual =["Year"]; //[{reviewid:0,reviewName:"Year",startDate:"",endDate:""},];
const sdata=[month,quartr,half,annual]
export {
  TabsConst,
  DesDeta,
  DepartData,
  KraTDeta,
  PeriodDeta,
  locData,
  KraDeta,
  year,
  month,
  period,
  MasterConstant,
  quartr,
  half,
  annual,sdata
};
