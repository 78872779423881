import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { Card } from "components/Form";
import { StyleContainer } from "./style.js";
import { HomeConst, EmpConst } from "./constant";
import { getAuthRole } from "modules/helper.js";
var userRole = getAuthRole();

class Home extends Component {
  componentDidMount() {
    try {
      userRole = getAuthRole();
      userRole === 4 && this.props.history.push("/profile");
    } catch (error) {
      console.log(error);
    }
  }
  render() {
    if (!localStorage.auth) return <></>;
    return (
      <StyleContainer>
        <div className="pDiv">
          <h2 className="header">{HomeConst.home}</h2>
          <div className="empDiv">
            <h2>{EmpConst.emp}</h2>
            <div className="cardDiv anime">
              {EmpConst.map((a, i) => (
                <Card key={i}>
                  <span
                    className="text"
                    onClick={() => this.props.history.push(a.link)}
                  >
                    {a.txt}
                  </span>
                </Card>
              ))}
            </div>
          </div>
        </div>
      </StyleContainer>
    );
  }
}

export default withRouter(Home);
