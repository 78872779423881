import React, { Component } from "react";
import { Modal } from "antd";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// import { ModalStyle } from "./style";
import { AddModConst} from "./constant";
import { Label, Input, Button, Select } from "components/Form";
import { SaveMaster, getMaster, saveKRA, getKRA } from "redux/master/action";

class AddModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mid: 0,
      kraid: 0,
      kraTypeID: 0,
      common: "",
      kraType: "",
      kra: "",
      commonErroe: false,
      kraTypeError: false,
      kraError: false,
      kraTypeOps: [],
    };
  }
  async componentDidMount() {
    try {
      const { selectData, title, editData } = this.props;
      if (selectData && title.toLowerCase() === "kra") this.setSelectData(selectData);
      if (editData?.mid || editData?.kraid) this.setData(editData);
    } catch (error) {
      console.log(error);
    }
  }
  setSelectData = (data) => {
    try {
      let collectData = [];
      data?.forEach((e, i) => {
        collectData.push({ id: e.mid, value: e.mname });
      });
      this.setState({ kraTypeOps: collectData });
    } catch (error) {
      console.log(error);
    }
  };
  setData = (data) => {
    const { title } = this.props;
    if (title.toLowerCase() !== "kra")
      this.setState({ mid: data.mid, common: data.mname });
    else {
      this.setState({
        kraid: data.kraid,
        kraTypeID: data.kratypeid,
        kraType: data.mname,
        kra: data.kraname,
      });
    }
  };
  handleCommon = (val) => {
    try {
      let a = val.target.value;
      this.setState({ common: a, commonErroe: a.trim() === "" });
    } catch (error) {
      console.log(error);
    }
  };
  commonSubmit = async () => {
    try {
      const { common, mid } = this.state;
      const { masterID ,orgID } = this.props;
      let flag = false;
      if (common.trim() === "") {
        flag = true;
        this.setState({ commonErroe: true });
      }
      if (!flag) {
        let postData = {
          mid: mid,
          mtid: masterID,
          mname: common.trim(),
          mdesc: "",
          orgid:orgID
        };
        await this.props.SaveMaster(postData);
        await this.props.getMaster(masterID+"/"+orgID);

        this.props.onOk();
      }
    } catch (error) {
      console.log(error);
    }
  };
  kraSubmit = async () => {
    try {
      const { kra, kraType, kraTypeID, kraid } = this.state;
      const {orgID} = this.props
      let flag = false;
      if (kra.trim() === "") {
        flag = true;
        this.setState({ kraError: true });
      }
      if (kraType.trim() === "") {
        flag = true;
        this.setState({ kraTypeError: true });
      }
      if (!flag) {
        let postData = {
          kraid: kraid,
          kraname: kra.trim(),
          kratypeid: kraTypeID,
          orgid:orgID,
        };
        await this.props.saveKRA(postData);
        await this.props.getKRA(orgID);
        this.props.onOk();
      }
    } catch (error) {
      console.log(error);
    }
  };
  selectUI = (val, error) => {
    try {
      const { kraTypeOps } = this.state;
      return (
        <Select
          value={val}
          defaultValue={val}
          data={kraTypeOps}
          className={error ? "empty" : ""}
          onChange={(value, data) =>
            this.setState({
              kraType: value,
              kraTypeID: data.id,
              kraTypeError: false,
            })
          }
          withID
        />
      );
    } catch (error) {
      console.log(error);
    }
  };
  render() {
    const { onOk, onCancel, title } = this.props;
    const {
      common,
      commonErroe,
      kraType,
      kraTypeError,
      kra,
      kraError,
      mid,
      kraid,
    } = this.state;
    return (
        <Modal
          visible={true}
          onOk={onOk}
          onCancel={onCancel}
          footer={null}
          title={`${
            mid !== 0 || kraid !== 0 ? AddModConst.Edit : AddModConst.new
          } ${title}`}
          getContainer={() => document.getElementById("addModal-form")}
          className ="addModalForm"
          centered
        >
          {title.toLowerCase() !== "kra" ? (
            <>
              <div className="fieldDiv">
                <Label
                  title={`${
                    title.toLowerCase() === "department"
                      ? AddModConst.dept
                      : title.toLowerCase() === "location"
                      ? AddModConst.location
                      : title.toLowerCase() === "zone"
                      ? AddModConst.zone
                      : title.toLowerCase() === "vertical"
                      ? AddModConst.vertical
                      : title.toLowerCase() === "kra type"
                      ? AddModConst.kraType
                      : title.toLowerCase() === "period"
                      ? AddModConst.prd
                      : AddModConst.desg
                  } ${AddModConst.st}`}
                  className={`label ${commonErroe ? "empty" : ""}`}
                />
                <Input
                  value={common}
                  max={500}
                  handleChange={this.handleCommon}
                  className={commonErroe ? "empty" : ""}
                />
              </div>
              <div className="btnDiv">
                <div className="btn">
                  <Button onClick={this.commonSubmit}>
                    {AddModConst.crtNew}
                  </Button>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="fieldDiv">
                <Label
                  title={AddModConst.kraType + AddModConst.st}
                  className={`label ${kraTypeError ? "empty" : ""}`}
                />
                {kraType === "" && this.selectUI("", kraTypeError)}
                {kraType !== "" && this.selectUI(kraType)}
                <Label
                  title={AddModConst.kra + AddModConst.st}
                  className={`label ${kraError ? "empty" : ""}`}
                />
                <Input
                  value={kra}
                  className={kraError ? "empty" : ""}
                  max={500}
                  handleChange={(val) => {
                    this.setState({
                      kra: val.target.value,
                      kraError: val.target.value.trim() === "",
                    });
                  }}
                />
              </div>
              <div className="btnDiv">
                <div className="btn">
                  <Button onClick={this.kraSubmit}>{AddModConst.crtNew}</Button>
                </div>
              </div>
            </>
          )}
        </Modal>
    );
  }
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  SaveMaster: (payload) => dispatch(SaveMaster(payload)),
  getMaster: (id) => dispatch(getMaster(id)),
  saveKRA: (payload) => dispatch(saveKRA(payload)),
  getKRA: (payload) => dispatch(getKRA(payload)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddModal)
);
