import styled from "styled-components";
import { size } from "App/device";
import { Theme } from "App/theme";

const StyleContainer = styled.div`
  .tabsDiv {
    display: flex;
    margin-top: 1em;
    overflow-x: auto;
    .tabComp {
      padding: 8px 20px;
      margin-right: 10px;
      color: ${Theme.mainColor};
      font-weight: 600;
      border-radius: 0.5em;
      background: #fff;
      @media ${size["laptop-sm-max"]} {
        padding: 8px 5px;
        margin-right: 2px;
      }
      &.selectedTab,
      :hover {
        font-weight: 500;
        color: #fff;
        background: ${Theme.mainColor};
      }
    }
  }
  .tableDiv {
    box-shadow: 0 0 10px rgb(0 0 0 / 20%);
    background-color: #fff;
    border-radius: 1em;
    padding: 1.5em;
    overflow-x: auto;
    margin-bottom: 1em;
    margin-top: 1em;
    .headDiv {
      h2 {
        margin-bottom: 0;
      }
      .actDiv {
        display: flex;
        align-items: center;
        margin-left: auto;
        .searchDiv {
          margin-right: 1em;
        }
      }
      @media ${size["mobile-md-max"]} {
        display: block !important;
        .actDiv {
          .searchDiv {
            margin-left: auto !important;
          }
          .btnDiv {
            margin-left: 0 !important;
          }
        }
      }
    }
    .tableView {
      overflow-x: auto;
      .valueDiv {
        border-collapse: collapse;
        border-spacing: 0;
        width: 100%;
        border: 1px solid #ddd;
        margin-top: 1em;
        @media ${size["tablet-max"]} {
          min-width: 52em;
        }
        th,
        td {
          text-align: left;
          padding: 16px;
        }
        thead {
          background-color: #f2f2f2;
        }
      }
    }
  }
`;
export { StyleContainer };
